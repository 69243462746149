import React from "react";

export interface ChargebackData {
  id: number;
  date: string;
  amount: string;
  businessName: string;
  transactionReference: string;
  dueDate: string;
  status: any;
  action: any;
}

export interface ChargebackBulkData {
  stage: string;
  type: string;
  provider: string;
  transactionReference: string;
  amount: string;
  reason: string;
}

export const ColumnChargeback = [
  {
    Header: "Business name",
    accessor: "businessName",
  },
  {
    Header: "Transaction reference",
    accessor: "transactionReference",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Date Created",
    accessor: "date",
  },
  {
    Header: "Due date",
    accessor: "dueDate",
  },

  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "action",
  },
];

export const ColumnChargebackBulk = [
  {
    Header: "Transaction reference",
    accessor: "transactionReference",
  },
  {
    Header: "Provider",
    accessor: "provider",
  },
  {
    Header: "Stage",
    accessor: "stage",
  },
  {
    Header: "Type",
    accessor: "type",
  },

  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Chargeback Reason",
    accessor: "reason",
  },
];
