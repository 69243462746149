import moment from "moment";

export const dateNow = moment().format("YYYY-MM-DD");
export const sevenDaysAgo = moment().subtract(7, "day").format("YYYY-MM-DD");
export const thirtyDaysAgo = moment().subtract(30, "day").format("YYYY-MM-DD");
export const startOfYear = moment().subtract(365, "day").format("YYYY-MM-DD");
export const endOfYear = moment().format("YYYY-MM-DD");

export const setTimeOnDate = (
  date: any,
  hour: number,
  minute: number,
  second: number
) => {
  if (!moment(date as string).isValid()) {
    return "";
  }
  const test = new Date(date as string);
  test.setHours(hour, minute, second, 0);

  const formattedDate = moment(test).format("YYYY-MM-DD HH:mm:ss");

  return formattedDate;
};
