export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    zIndex: "1000",
    marginTop: "30px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const computeCustomStyles = (size = "10%") => ({
  content: {
    top: size,
    left: size,
    right: size,
    bottom: size,
    // marginRight: "-50%",
    padding: "0",
    // transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    zIndex: "1000",
    marginTop: "30px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
