import React from "react";
import { ClipLoader } from "react-spinners";
import styles from "./page-loader.module.scss";

export const PageLoader: React.FC = () => {
  return (
    <div className={styles.loader}>
      <ClipLoader color="#8e314f" />
    </div>
  );
};
