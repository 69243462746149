import React, { useEffect, useState } from "react";
import styles from "./Business.module.scss";
import Modal from "react-modal";
import { computeCustomStyles } from "../../../constants/modalStyles";
import Grid from "@mui/material/Grid";
import Fees from "../../Fee&limit/Fees/Fees";
import Collection from "../../Fee&limit/Collection Limit/Collection";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@material-ui/icons";
import Button from "../../../components/button/Button";
import subsidiaryApi from "../../../api/subsidiaries";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import OutlineTextInput from "../../../components/input/OutlineTextInput";

interface initTypes {
  chargebackReceiptEmailRecipients: string[];
  fundingReceiptEmailRecipients: string[];
}

const initProps: initTypes = {
  fundingReceiptEmailRecipients: [],
  chargebackReceiptEmailRecipients: [],
};

function SubsidiaryPreferenceSection({
  display,
  subdiaryInfo,
  groupInformation,
}: any) {
  const dispatch = useDispatch();
  const [config, setConfig] = React.useState<any>(
    groupInformation?.configs || []
  );
  const [emailInput, setEmailInput] = useState({
    funding: "",
    chargeback: "",
  });
  const [inputs, setInputs] = React.useState(initProps);
  const updateSubsidiaryConfig = (
    envid: number,
    configName: string = "noAuthChargeEnabled"
  ) => {
    let env: any = getEnvConfig(envid);
    if (env == null) {
      env = { appEnvironmentId: envid, [configName]: true };
      config.push(env);
    } else {
      env[configName] = !env[configName];
    }
    setConfig([...config]);
  };

  const getEnvConfig = (envid: number): any =>
    config.find((x: any) => x.appEnvironmentId == envid);

  const updateSubsidiaryConfigHandler = () => {
    dispatch(openLoader());

    const payload = config.map((cfg: any) => ({ ...cfg, ...inputs }));

    subsidiaryApi
      .updateSubsidiaryConfig(subdiaryInfo?.businessInformation?.id, payload)
      .then((res: any) => {
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: res?.data?.message,
          })
        );
      })
      .catch((err) => {
        console.log({ xx: err.response });
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: err?.response?.data?.message,
          })
        );
      })
      .finally(() => {
        dispatch(closeLoader());
      });
  };

  useEffect(() => {
    setConfig(groupInformation?.configs || []);
    // Populate email fields if data exists

    // Populate email fields as arrays if data exists
    if (subdiaryInfo?.businessInformation) {
      setInputs({
        fundingReceiptEmailRecipients:
          subdiaryInfo.businessInformation.fundingReceiptEmailRecipients?.split(
            ","
          ) || [],
        chargebackReceiptEmailRecipients:
          subdiaryInfo.businessInformation.chargebackReceiptEmailRecipients?.split(
            ","
          ) || [],
      });
    }
  }, [groupInformation?.configs, subdiaryInfo?.businessInformation]);

  // for the sub add pref

  const addEmail = (type: "funding" | "chargeback") => {
    if (!emailInput[type].trim()) return;
    setInputs((prevState) => ({
      ...prevState,
      [type === "funding"
        ? "fundingReceiptEmailRecipients"
        : "chargebackReceiptEmailRecipients"]: [
        ...prevState[
          type === "funding"
            ? "fundingReceiptEmailRecipients"
            : "chargebackReceiptEmailRecipients"
        ],
        emailInput[type].trim(),
      ],
    }));
    setEmailInput((prev) => ({ ...prev, [type]: "" }));
  };

  const removeEmail = (type: "funding" | "chargeback", index: number) => {
    setInputs((prevState) => {
      const updatedEmails = [
        ...prevState[
          type === "funding"
            ? "fundingReceiptEmailRecipients"
            : "chargebackReceiptEmailRecipients"
        ],
      ];
      updatedEmails.splice(index, 1);
      return {
        ...prevState,
        [type === "funding"
          ? "fundingReceiptEmailRecipients"
          : "chargebackReceiptEmailRecipients"]: updatedEmails,
      };
    });
  };

  return (
    <div
      style={{
        padding: "0 24px 24px 24px",
        display: display ? "" : "none",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div
            className={styles.subsidiaryGroupWrapper2}
            onClick={() => updateSubsidiaryConfig(1)}
          >
            {getEnvConfig(1)?.noAuthChargeEnabled ? (
              <CheckBoxOutlined />
            ) : (
              <CheckBoxOutlineBlank />
            )}{" "}
            <div className={styles.detailsValue}>
              Enable 2D Charge On Test Environment
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div
            className={styles.subsidiaryGroupWrapper2}
            onClick={() => updateSubsidiaryConfig(2)}
          >
            {getEnvConfig(2)?.noAuthChargeEnabled ? (
              <CheckBoxOutlined />
            ) : (
              <CheckBoxOutlineBlank />
            )}{" "}
            <div className={styles.detailsValue}>
              Enable 2D Charge On Live Environment
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div
            className={styles.subsidiaryGroupWrapper2}
            onClick={() =>
              updateSubsidiaryConfig(1, "internationalChargeEnabled")
            }
          >
            {getEnvConfig(1)?.internationalChargeEnabled ? (
              <CheckBoxOutlined />
            ) : (
              <CheckBoxOutlineBlank />
            )}{" "}
            <div className={styles.detailsValue}>
              Enable International Charge On Test Environment
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <div
            className={styles.subsidiaryGroupWrapper2}
            onClick={() =>
              updateSubsidiaryConfig(2, "internationalChargeEnabled")
            }
          >
            {getEnvConfig(2)?.internationalChargeEnabled ? (
              <CheckBoxOutlined />
            ) : (
              <CheckBoxOutlineBlank />
            )}{" "}
            <div className={styles.detailsValue}>
              Enable International Charge On Live Environment
            </div>
          </div>
        </Grid>
      </Grid>
      {/* Emails */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={styles.accounts}>
            <div className={styles.firsthand}>
              <div className={styles.accountInfo}>Email Recipients</div>
              <div className={styles.accountEarnings}>
                Set email recipients for funding and chargeback receipts
              </div>
            </div>

            <div className={styles.secondhand}>
              {["funding", "chargeback"].map((type) => (
                <div key={type} className={styles.paymentList}>
                  <div className={styles.filledaccountInfo}>
                    {type === "funding"
                      ? "Funding Receipt Email Recipients"
                      : "Chargeback Receipt Email Recipients"}
                  </div>
                  <div style={{ display: "flex", gap: "8px", width: "100%" }}>
                    <OutlineTextInput
                      handleChange={(e) =>
                        setEmailInput({ ...emailInput, [type]: e.target.value })
                      }
                      inputName={type}
                      inputLabel="Enter Email Recipient"
                      inputValue={emailInput[type as "funding" | "chargeback"]}
                    />
                    <Button
                      value="+"
                      onClick={() => addEmail(type as "funding" | "chargeback")}
                    />
                  </div>
                  <ul
                    className={styles.emailList}
                    style={{
                      display: "flex",
                      gap: "8px",
                      width: "100%",
                      listStyleType: "none",
                      margin: "0px",
                      padding: "0px",
                    }}
                  >
                    {inputs[
                      type === "funding"
                        ? "fundingReceiptEmailRecipients"
                        : "chargebackReceiptEmailRecipients"
                    ].map((email, index) => (
                      <>
                        <li key={index}>{email} </li>
                        <span
                          style={{
                            cursor: "pointer",
                            color: "white",
                            marginLeft: "3px",
                            marginTop: "3px",
                            background: "red",
                            padding: "1px 4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "18px",
                          }}
                          onClick={() =>
                            removeEmail(type as "funding" | "chargeback", index)
                          }
                        >
                          &times;
                        </span>
                      </>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            value="Save preferences"
            onClick={updateSubsidiaryConfigHandler}
            className={styles.downloadbutton}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default SubsidiaryPreferenceSection;

// const work = {
//   config: {
//     "0": {
//       subsidiaryId: 23,
//       noAuthChargeEnabled: false,
//       internationalChargeEnabled: true,
//       appEnvironmentId: 1,
//       transactionNotificationEnabled: false,
//       allowCustomVirtualAccountName: null,
//       id: 14,
//       dateCreated: "2024-05-11T19:04:56.4807819",
//       dateUpdated: "2025-03-21T07:52:37.9795479",
//       dateDeleted: null,
//       createdBy: 2,
//       updatedBy: 2,
//       deletedBy: null,
//       fundingReceiptEmailRecipients: ["olu@gmail.com", "king@gmail.com"],
//       chargebackReceiptEmailRecipients: ["koko@gmail.com"],
//     },
//     "1": {
//       subsidiaryId: 23,
//       noAuthChargeEnabled: false,
//       internationalChargeEnabled: true,
//       appEnvironmentId: 2,
//       transactionNotificationEnabled: false,
//       allowCustomVirtualAccountName: null,
//       id: 13,
//       dateCreated: "2024-05-11T19:04:48.1110525",
//       dateUpdated: "2025-03-21T07:52:38.0821975",
//       dateDeleted: null,
//       createdBy: 2,
//       updatedBy: 2,
//       deletedBy: null,
//       fundingReceiptEmailRecipients: ["olu@gmail.com", "king@gmail.com"],
//       chargebackReceiptEmailRecipients: ["koko@gmail.com"],
//     },
//   },
// };
