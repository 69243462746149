export const formatConfigPayload = (payload: any, inputTypeText: any) => {
  const limitValues: any[] = [];
  for (const keyName in payload) {
    if (keyName.indexOf("limitItem") < 0) continue;
    const splitt = keyName.split("_");
    const id = splitt[1];
    let entry: any = limitValues.find((x) => x.LimitItem == id);
    if (!entry) entry = { LimitItem: id };
    if (keyName.indexOf("limitItemInternational") > -1) {
      entry.InternationalValue = payload[keyName];
      continue;
    }
    entry.Value = payload[keyName];
    limitValues.push(entry);
  }
  // console.log({ x: limitValues })
  return {
    SubsidiaryGroup: {
      SubsidiaryGroupId: inputTypeText.subsidiaryGroup
        ? null
        : payload.subsidiaryGroupId,
      Name: payload.subsidiaryGroupName,
      Description: payload.subsidiaryGroupDescription,
    },
    pricingGroup: {
      PricingGroupId: inputTypeText.pricingGroup
        ? null
        : payload.pricingGroupId,
      Name: payload.pricingGroupName,
      PaymentOption: payload.paymentOption,
      Currencies: [payload.currency ?? 0],
      Fee: payload.fee / 100,
      Cap: payload.cap,
      FlatValue: payload.flatValue,
      InternationalFee: payload.internationalFee / 100,
      InternationalCap: payload.internationalCap,
      InternationalFlatValue: payload.internationalFlatValue,
      minimumFeeValue: payload.minimumFeeValue,
      minimumFeeInternationalValue: payload.minimumFeeInternationalValue,
      chargebackFeeValue: payload.chargebackFeeValue,
      refundFeeValue: payload.refundFeeValue,
    },
    LimitGroup: {
      limitGroupId: inputTypeText.limitGroup ? null : payload.limitGroupId,
      Name: payload.limitGroupName,
      Currencies: [payload.currency ?? 0],
      limits: limitValues,
    },
    SettlementGroup: {
      SettlementGroupId: inputTypeText.settlementGroup
        ? null
        : payload.settlementGroupId,
      Name: payload.settlementGroupName,
      Currencies: [payload.settlementCurrency ?? 0],
      SettlementDestinationId: payload.settlementDestinationId,
      SettlementType: payload.settlementType,
      InitialSettlementPercentage: payload.initialSettlementPercentage / 100,
      InitialSettlementDay: payload.initialSettlementDay,
      FinalSettlementDay: payload.finalSettlementDay,
    },
  };
};

export const formatLimitValueToForm = (limitList: any[]) => {
  const result: any = {};
  limitList.map((item: any) => {
    result[`limitItem${item?.limitItem?.name}_${item?.limitItem?.id}`] =
      item?.value;
    result[
      `limitItemInternational${item?.limitItem?.name}_${item?.limitItem?.id}`
    ] = item?.internationalValue;
  });
  // console.log({ result })
  return result;
};
