import React, { useState, Fragment, useEffect } from "react";
import styles from "./fundingInfo.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnFundingHistory,
  FundingHistoryData,
} from "../../types/TablesTypes/FundingHistory";
import TableHeader from "../../components/TableHeader/TableHeader";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { numberWithCommas } from "../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
// import PendingApprovalMenu from '../../../components/MenuOption/pendingApproval/PendingApproval';
// import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
// import { ReactComponent as CopyIcon } from '../../../assets/images/Icons/u_copy.svg';
import { Dayjs } from "dayjs";
import FilterButtonNew from "../../components/FilterButtonNew/FilterButtonNew";
import {
  thirtyDaysAgo,
  dateNow,
  endOfYear,
  sevenDaysAgo,
  startOfYear,
  setTimeOnDate,
} from "../../utils/datefunction";
import { dateEventFilters } from "../../constants/date-event-filters";

type Props = {
  subsidiaryId: number;
  defaultRowsPerPage?: number;
};

export const FundingInfo: React.FC<Props> = ({
  subsidiaryId,
  defaultRowsPerPage,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [tableRow, setTableRow] = useState<any[]>();
  const [funding, setFunding] = useState<any>();

  //for filtering
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState(dateEventFilters.last30days);
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    defaultRowsPerPage ?? 10
  );
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  const [paymentreference, setPaymentReference] = useState("");
  const [orderreference, setOrderReference] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const clearHandler = () => {
    setFromDate("");
    setToDate("");
    setPaymentReference("");
    setOrderReference("");
    setStatus("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    id?: number;
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      id: 1,
      name: "Status",
      value: status,
      setValue: setStatus,
      type: "select",
      selective: [
        { name: "Successful", value: "Successful" },
        { name: "Initiated", value: "Initiated" },
        { name: "Awaiting-Confirmation", value: "Awaiting-Confirmation" },
      ],
    },
    {
      id: 2,
      name: "Payment Reference",
      value: paymentreference,
      setValue: setPaymentReference,
      type: "text",
    },
    {
      id: 3,
      name: "Order Reference",
      value: orderreference,
      setValue: setOrderReference,
      type: "text",
    },
  ];
  const getTransactionsApi = useApi(transactionsApi.getTransactions);

  const fetchFunding = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(
        `wallet/${subsidiaryId}/funding/history?fromdate=${setTimeOnDate(
          fromDate,
          0,
          0,
          0
        )}&todate=${setTimeOnDate(toDate, 23, 59, 59)}`
      );
      setFunding(data?.data);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchFunding();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchFunding();
    }
  }, [bearer]);

  useEffect(() => {
    setBearer(true);
  }, [rowsPerPage, pageNumber]);

  const dataTransaction = () => {
    const tempArr: FundingHistoryData[] = [];
    funding?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          amount: `${transaction.currency} ${numberWithCommas(
            transaction.amount
          )}`,
          method: transaction.method ?? "Bank Transfer",
          fee: transaction.fee,
          date: moment(transaction?.dateCreated).format(
            "DD MMM YYYY hh:mm:ss a"
          ),
          action: <></>,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [funding?.items]);

  return (
    <Fragment>
      <TableHeader
        pageName="Funding History"
        dataLength={funding?.totalItems}
        data={funding?.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        setDropdown={setDropdown}
        filtering={false}
        // otherbuttonTitle='Fund balance'
        // otherbuttonfunction={setIsOpen}
        FilterComponent={
          <>
            <FilterButtonNew
              // eventDate={eventDate}
              // setEventDate={setEventDate}
              dropdown={dropdown}
              setDropdown={setDropdown}
              setFromDate={setFromDate}
              setEventDate={setEventDate}
              setToDate={setToDate}
              fromDate={fromDate}
              toDate={toDate}
              setBearer={setBearer}
              applyAllHandler={applyAllHandler}
              clearAllHandler={clearHandler}
              filteredArray={filteredArray}
            />
          </>
        }
      />

      {getTransactionsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnFundingHistory ? ColumnFundingHistory : []}
          value={value}
          emptyPlaceHolder="You currently do not have any data"
          total={funding?.totalItems}
          totalPage={funding?.total}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          nextPage={nextPage}
          setNextPage={setNextPage}
          previousPage={previousPage}
          setPreviousPage={setPreviousPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}
      {/* <Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<div className={styles.modalwrapper}>
					<div className={styles.modalhead}>
						<h3 className={styles.modalheadh3}>Fund Balance</h3>
						<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
					</div>
					<Divider style={{ margin: 0, padding: 0 }} />
					<div className={styles.modalcontent}>
						<p className={styles.modalcontentp}>
							To fund your naira wallet, make a transfer to the account details
							below
						</p>

						<div className={styles.modalcontentbox}>
							<div className={styles.modalcontentboxflex}>
								<p className={styles.modalcontentboxp}>Bank name:</p>
								<p className={styles.modalcontentboxp}>Wema Bank Plc</p>
							</div>
							<div className={styles.modalcontentboxflex}>
								<p className={styles.modalcontentboxp}>Account number:</p>
								<p className={styles.modalcontentboxp}>
									1839582940{' '}
									<span>
										<CopyIcon />
									</span>
								</p>
							</div>
							<div className={styles.modalcontentboxflex}>
								<p className={styles.modalcontentboxp}>Account name:</p>
								<p className={styles.modalcontentboxp}>TransactPay Limited</p>
							</div>
						</div>

						<div className={styles.modalselect}>
							<label className={styles.inputLabel}>
								Which balance do you want to fund?
							</label>
							<Select
								defaultValue={selectedPaymentType}
								onChange={setSelectedPaymentType}
								options={selectedPaymentTypeOptions}
								// styles={customStyles}
								className={styles.select}
								styles={{
									// Fixes the overlapping problem of the component
									menu: (provided) => ({
										...provided,
										zIndex: 9999,
										width: '100%',
									}),
								}}
							/>
						</div>
						<div className={styles.input}>
							<OutlineTextInput
								handleChange={updateProps}
								inputName='Funding amount'
								inputLabel='Funding amount'
								inputValue={inputs}
								style={{ width: '100%' }}
							/>
						</div>

						<Divider style={{ margin: 0, padding: 0 }} />

						<div className={styles.modalFooter}>
							<button className={styles.cancel} onClick={closeModal}>
								Cancel
							</button>

							<button className={styles.fund}>Fund</button>
						</div>
					</div>
				</div>
			</Modal> */}
    </Fragment>
  );
};
