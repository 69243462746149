import { useEffect, useState } from "react";
import styles from "./../Chargeback.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/closenew.svg";
import { Divider } from "antd";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import client from "../../../api/client";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MultipleUpload from "../../../components/multipleUpload/MultipleUpload";
import bulkChargebackSample from "../../../assets/documents/bulk-chargeback.xlsx";
import { useMutation } from "@tanstack/react-query";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";

function Bulk({ closeModal, setBearer, openModal }: any) {
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch();

  const { mutate, isLoading, isError, error } = useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      const response: any = await client.post("chargeback/bulk", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const message = response?.data?.message;
      closeModal();
      setBearer(true);
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    },
  });

  useEffect(() => {
    if (!isLoading) {
      dispatch(closeLoader());
    }
  }, [isLoading, dispatch]);

  const uploadMultipleFile = async () => {
    if (!file) return window.alert("please, add a refund");

    dispatch(openLoader());
    try {
      mutate(file);
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(closeLoader());
      console.log({ error });

      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message ?? "something went wrong",
        })
      );
      openModal();
    }
  };

  const saveFile = (file?: File) => {
    if (!file) {
      return;
    }

    setFile(file);
  };

  return (
    <div className={styles.modalwrapper}>
      <div className={styles.modalhead}>
        <h3 className={styles.modalheadh3}>Log bulk chargeback</h3>
        <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
      </div>
      {isError ? (
        <div className={styles.modalcontent}>
          <h3>Error Processing File</h3>
          <p>
            {(error as any)?.response?.data?.message ?? "Refund upload failed"}
          </p>
          {(error as any)?.response?.data?.errors && (
            <>
              {
                <table>
                  <thead>
                    <tr>
                      <th>Reference</th>
                      <th>Errors</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries((error as any)?.response?.data?.errors).map(
                      ([key, messages]: any) => (
                        <tr key={key}>
                          <td>{key}:</td>
                          <td>
                            <ul>
                              {messages.map((msg: any, index: any) => (
                                <li key={index}>{msg}</li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              }
            </>
          )}
        </div>
      ) : (
        <>
          <Divider style={{ margin: 0, padding: 0 }} />
          <div className={styles.modalcontent}>
            <MultipleUpload
              onDrop={(result: any) => {
                // setFile(result);
              }}
              saveFile={saveFile}
            />

            <a
              href={bulkChargebackSample}
              download
              className={styles.modaluploadp}
            >
              Download sample Excel file for bulk chargeback
            </a>
            <Divider style={{ margin: 0, padding: 0 }} />

            <div className={styles.modalFooter}>
              <button className={styles.cancel} onClick={closeModal}>
                Cancel
              </button>

              <button onClick={uploadMultipleFile} className={styles.fund}>
                Continue
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Bulk;
