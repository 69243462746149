import React, { Fragment, useState } from "react";
import styles from "./Exportbutton.module.scss";
import { ReactComponent as DropArrow } from "../../assets/images/Icons/drop-arrow.svg";
import { exportToXLS } from "../../utils/exportToExcel";
import * as XLSX from "xlsx";
import { CSVLink, CSVDownload } from "react-csv";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useDispatch } from "react-redux";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";

const ExportButton = ({
  data,
  fileName,
  headers,
  fetchDataHandler,
  handleFetchData,
}: any) => {
  var dispatch = useDispatch();
  const [exportData, setExportData] = useState(data);

  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = async () => {
    if (
      !dropdown &&
      (exportData?.length < 1 || exportData?.length != data?.length)
    )
      await fetchExportData();
    setDropdown(!dropdown);
  };

  const shouldCallApiToGetData =
    (handleFetchData && typeof handleFetchData === "function") ||
    (fetchDataHandler && typeof fetchDataHandler === "function");

  const handleClick = async (type: XLSX.BookType = "xlsx") => {
    if (!shouldCallApiToGetData) {
      exportToXLS(data, fileName, type);
      return;
    }
    if (handleFetchData) {
      const apiData = await handleFetchData();
      exportToXLS(apiData, fileName, type);
      return;
    }
    if (fetchDataHandler) {
      const apiData = await fetchDataHandler();
      exportToXLS(apiData, fileName, type);
      return;
    }
  };

  const fetchExportData = async () => {
    if (!fetchDataHandler) return;
    dispatch(openLoader());
    const d = (await fetchDataHandler()) || data || [];
    setExportData([...d]);
    dispatch(closeLoader());
  };

  const handleClickAway = () => {
    setDropdown(false);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.exportMenu}>
          <div className={styles.export} onClick={handleDropdown}>
            Export <DropArrow className={styles.dropArrow} />
          </div>

          {dropdown && (
            <div className={styles.exportDropdown}>
              <div onClick={() => handleClick()} style={{ cursor: "pointer" }}>
                Export as .xls
              </div>
              <div
                onClick={() => handleClick("csv")}
                style={{ cursor: "pointer" }}
              >
                Export to .csv
              </div>
              {/* <CSVLink
                data={exportData ? exportData : data}
                //   headers={headers}
                filename={fileName}
                className={styles.csv}
              >
                Export to .csv
              </CSVLink> */}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </Fragment>
  );
};

export default ExportButton;
