// import client from "./client";

// const getAllPayouts = () => client.get("/merchants/payouts");
// export default { getAllPayouts };

import client from "./client";
import { setTimeOnDate } from "../utils/datefunction";

const getAllPayouts = (params) => {
  return client.get("/merchants/payouts", {
    params: {
      SearchableColumns: params.SearchableColumns || "",
      PageNumber: params.PageNumber || 1,
      PageSize: params.PageSize || 10,
      StartDate: setTimeOnDate(params.StartDate, 0, 0, 0) || "",
      // StartDate: params.StartDate || '',
      // EndDate: params.EndDate || '',
      EndDate: setTimeOnDate(params.EndDate, 23, 59, 59) || "",
      Filter: params.Filter || null,
      SelectedSearchColumn: params.SelectedSearchColumn || "",
      Keyword: params.Keyword || "",
      PageSkip: params.PageSkip || 0,
    },
  });
};

const getAllUsdPayouts = (params) => {
  return client.post("/multi-currency/pending", {
    params: {
      SearchableColumns: params.SearchableColumns || "",
      PageNumber: params.PageNumber || 1,
      PageSize: params.PageSize || 10,
      StartDate: setTimeOnDate(params.StartDate, 0, 0, 0) || "",
      // StartDate: params.StartDate || '',
      // EndDate: params.EndDate || '',
      EndDate: setTimeOnDate(params.EndDate, 23, 59, 59) || "",
      Filter: params.Filter || null,
      SelectedSearchColumn: params.SelectedSearchColumn || "",
      Keyword: params.Keyword || "",
      PageSkip: params.PageSkip || 0,
    },
  });
};

// Function to get pending payouts
const getPendingPayouts = (params) => {
  return client.get("/merchants/pending-payouts", {
    params: {
      SearchableColumns: params.SearchableColumns || "",
      PageNumber: params.PageNumber || 1,
      PageSize: params.PageSize || 10,
      // StartDate: params.StartDate || '',
      // EndDate: params.EndDate || '',
      StartDate: setTimeOnDate(params.StartDate, 0, 0, 0) || "",
      EndDate: setTimeOnDate(params.EndDate, 23, 59, 59) || "",
      Filter: params.Filter || null,
      SelectedSearchColumn: params.SelectedSearchColumn || "",
      Keyword: params.Keyword || "",
      PageSkip: params.PageSkip || 0,
    },
  });
};

// Function to get wema bank to bank  pending payouts
const getPendingBankToBankPayouts = (params) => {
  return client.get("/wema-debits", {
    params: {
      SearchableColumns: params.SearchableColumns || "",
      PageNumber: params.PageNumber || 1,
      PageSize: params.PageSize || 10,
      // StartDate: params.StartDate || '',
      // EndDate: params.EndDate || '',
      StartDate: setTimeOnDate(params.StartDate, 0, 0, 0) || "",
      EndDate: setTimeOnDate(params.EndDate, 23, 59, 59) || "",
      Filter: params.Filter || null,
      SelectedSearchColumn: params.SelectedSearchColumn || "",
      Keyword: params.Keyword || "",
      PageSkip: params.PageSkip || 0,
    },
  });
};

export default {
  getAllPayouts,
  getAllUsdPayouts,
  getPendingPayouts,
  getPendingBankToBankPayouts,
};
