import React, { useState, useEffect } from "react";
import styles from "./Fees.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import { ColumnFees, FeesData } from "../../../types/TablesTypes/fees";
import TableHeader from "../../../components/TableHeader/TableHeader";
import client from "../../../api/client";
import {
  openLoader,
  closeLoader,
} from "../../../redux/actions/loader/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import Mocked from "./fees.json";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { InputLabel, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Mark from "../../../assets/images/Icons/u_save.svg";
import { Divider } from "antd";
import { Grid } from "@material-ui/core";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/closenew.svg";
import { Dayjs } from "dayjs";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../../utils/datefunction";
import * as Yup from "yup";
import FilterModal from "../../../components/filterConfig/FilterModal";
import SelectWrapper from "../../../components/Select";
import moment from "moment";
import { numberWithCommas } from "../../../utils/formatNumber";
import FeesModal from "../../../components/ModalContent/FeesModal/FeesModal";
import MenuOption from "../../../components/MenuOption/MenuOption";
import FilterButtonNew from "../../../components/FilterButtonNew/FilterButtonNew";
import { dateEventFilters } from "../../../constants/date-event-filters";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input":
      {
        textAlign: "center",
        padding: "8.1px 14px",
      },
  },
  select: {
    "& .MuiOutlinedInput-root": {
      color: "#414141",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      borderRadius: "10px",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      outline: "none",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      backgroundColor: "#ffffff",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#E0E0E0",
      backgroundColor: "#ffffff",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #E0E0E0",
    },
  },
});

const Fees = ({ data }: any) => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [modalIsOpenSingle, setIsOpenSingle] = React.useState({
    isOpened: false,
    data: null,
  });
  const [fees, setFees] = useState<any>({ items: data ?? [] });

  function closeModalSingle() {
    setIsOpenSingle({ isOpened: false, data: null });
  }

  function openModalSingle(data: any = null) {
    // console.log({ data });
    setIsOpenSingle({ isOpened: true, data: data });
  }

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null | string>(
    thirtyDaysAgo
  );
  const [toDate, setToDate] = useState<Dayjs | null | string>(dateNow);
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const [businessname, setBusinessname] = useState("");
  const [businesstype, setBusinesstype] = useState("");

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setCountry("");
    setStatus("");
    setBusinesstype("");
  };
  const applyAllHandler = () => {
    // Ensure bearer triggers a re-fetch of transactions
    setBearer(true);
    setDropdown(false);
  };
  useEffect(() => {
    if (eventDate === dateEventFilters.today) {
      setFromDate(dateNow);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last7days) {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.last30days) {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === dateEventFilters.oneyear) {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray: {
    name: string;
    value: string | Dayjs | null;
    setValue: React.Dispatch<React.SetStateAction<any>>;
    selective?: { name: string; value: string }[];
    selectHelper?: boolean;
    type: "select" | "date" | "text";
  }[] = [
    {
      name: "Date Range",
      value: fromDate,
      setValue: setFromDate,
      type: "date", // Explicitly set as "date"
    },
    {
      name: "Business Type",
      value: businesstype,
      setValue: setBusinesstype,
      type: "select",
      selective: [
        { name: "Individual", value: "Individual" },
        { name: "Company", value: "Company" },
      ],
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      type: "select",
      selective: [
        { name: "Active", value: "Active" },
        { name: "Pending-Verification", value: "Pending-Verification" },
        { name: "New", value: "New" },
        { name: "Pending-Approval", value: "Pending-Approval" },
      ],
    },
  ];

  const fetchBusinesses = async () => {
    if (data) return;
    dispatch(openLoader());
    try {
      const res: any = await client.get(
        `pricing/collection?status=${status}&size=${rowsPerPage}&page=${pageNumber}`
      );
      setFees(res.data.data);
      setPageNumber(res?.data?.data?.page?.currentPage || 1);
      dispatch(closeLoader());
      setBearer(false);
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error.response.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchBusinesses();
  }, []);

  useEffect(() => {
    if (bearer) {
      fetchBusinesses();
    }
  }, [bearer, pageNumber, rowsPerPage]);

  useEffect(() => {
    setBearer(true);
  }, [pageNumber, rowsPerPage]);

  // useEffect(() => {
  // 	setPageNumber(fees?.page?.currentPage || 1);
  // 	setPreviousPage(fees?.page?.previousPage);
  // 	setNextPage(fees?.page?.nextPage);
  // }, [fees]);

  const dataTransaction = () => {
    const tempArr: FeesData[] = [];
    fees?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          id: transaction.id,
          type: transaction.type,
          country: transaction.country,
          internationalFlatValue: transaction?.internationalFlatValue,
          flatValue: transaction?.flatValue,
          paymentOption:
            transaction.paymentOption?.name || transaction.paymentOption,
          paymentOptionCode: transaction.paymentOptionCode,
          currency: transaction?.currency?.shortName || transaction?.currency,
          pricingGroup:
            transaction?.pricingGroup?.name || transaction?.pricingGroup,
          pricingGroupId: transaction?.pricingGroupId,
          cap: numberWithCommas(transaction?.cap, 4),
          fee: numberWithCommas(transaction?.fee * 100, 4),
          internationalCap: numberWithCommas(transaction?.internationalCap, 4),
          internationalFee: numberWithCommas(
            transaction?.internationalFee * 100,
            4
          ),
          dateCreated: moment(transaction?.dateCreated).format(
            "MMMM Do YYYY, h:mm a"
          ),
          action: (
            <MenuOption
              menuContent={
                <div>
                  {[].map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(transaction, e);
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name()}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>{option?.name()}</div>
                    </div>
                  ))}
                </div>
              }
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [fees?.items]);

  const itemLength = fees?.length;

  return (
    <div style={{ marginTop: "38px" }}>
      <TableHeader
        pageName="collection fee"
        dataLength={fees?.page?.total}
        data={fees?.items}
        value={value}
        setValue={setValue}
        exporting={false}
        filtering={false}
        searchfn={false}
        newButton={
          <div onClick={() => openModalSingle()} className={styles.createLink}>
            Add a new collection fee
          </div>
        }
        FilterComponent={
          <FilterButtonNew
            eventDate={eventDate}
            setEventDate={setEventDate}
            dropdown={dropdown}
            setDropdown={setDropdown}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            setBearer={setBearer}
            applyAllHandler={applyAllHandler}
            clearAllHandler={clearHandler}
            filteredArray={filteredArray}
          />
        }
      />

      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={ColumnFees ? ColumnFees : []}
        value={value}
        emptyPlaceHolder={
          fees?.page?.total == 0
            ? "You currently do not have any data"
            : "Loading..."
        }
        total={fees?.page?.total}
        totalPage={fees?.page?.totalPage}
        pageNumber={pageNumber}
        clickAction={(value: any) => {
          openModalSingle(value);
        }}
        setPageNumber={setPageNumber}
        nextPage={nextPage}
        setNextPage={setNextPage}
        previousPage={previousPage}
        setPreviousPage={setPreviousPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      {/* //MODAL FOR SINGLE */}
      <Modal
        isOpen={modalIsOpenSingle.isOpened}
        onRequestClose={closeModalSingle}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <FeesModal
          data={modalIsOpenSingle.data}
          closeModal={closeModalSingle}
          cb={fetchBusinesses}
        />
      </Modal>
    </div>
  );
};

export default Fees;
