import React, { useEffect } from "react";
import styles from "./Initiatepayout.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import Select from "react-select";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import BeneficiaryPayout from "./BeneficiaryPayout";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import PayoutVerificationModal from "../PayoutVerificationModal/PayoutVerificationModal";

const InternalWalletToMerchantPayout = ({
  closeModal,
  fn,
  beneficiary,
  fn2,
}: any) => {
  const dispatch = useDispatch();
  const [banks, setBanks] = React.useState<any>([]);
  const [wallets, setWallets] = React.useState<any>([]);
  const [selectedBank, setSelectedBank] = React.useState<any>();
  const [selectedWallet, setSelectedWallet] = React.useState<any>();
  const [selectedWalletOptions, setSelectedWalletOptions] = React.useState<
    any[]
  >([]);
  const [resolveMerchantData, setResolvedMerchantData] = React.useState<any>();
  const [resolveData, setResolvedData] = React.useState<any>();
  const [active, setActive] = React.useState("new");
  const [currenciesOption, setCurrenciesOption] = React.useState<any>([]);
  const { currencies, currency } = useSelector(
    (state) => state?.appSettingsReducer
  );
  // const [selectedCurrencyInput, setSelectedCurrencyInput] = React.useState<any>(
  //   {
  //     label: currency,
  //     value: currency,
  //   }
  // );
  const [selectedCurrencyInput, setSelectedCurrencyInput] = React.useState<any>(
    currencies && currencies.length
      ? currencies.find((c: any) => c.shortName === currency) || {
          value: "", // Default to an empty state if not found
          label: currency, // Show short name until user selects
          shortName: currency,
        }
      : null
  );

  const [modalIsOpen, setIsModalOpen] = React.useState({
    isOpened: false,
    data: null,
  });

  const closePayoutVerificationModal = () => {
    setIsModalOpen({ isOpened: false, data: null });
    // fn();
    // fn2();
    closeModal();
  };

  const fetchBanks = async () => {
    try {
      const data: any = await client.get("/payout/banks");
      setBanks(data?.data?.banks);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  // Fetch internal wallets
  const fetchWallets = async () => {
    try {
      const data: any = await client.get("/wallet/internal");
      setWallets(data?.data); // Store the entire product data array
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };
  // Fetch currencies and sort them, prioritizing NGN (Nigerian Naira) first
  useEffect(() => {
    if (currencies && currencies.length) {
      const sortedCurrencies = [...currencies].sort((a: any, b: any) => {
        if (a.shortName === "NGN") return -1;
        if (b.shortName === "NGN") return 1;
        return a.shortName.localeCompare(b.shortName); // Sort alphabetically for other currencies
      });
      setCurrenciesOption(
        sortedCurrencies.map((currency: any) => ({
          value: currency.id,
          label: currency.name, // Showing the full name of the currency
          shortName: currency.shortName,
        }))
      );
    }
  }, [currencies]);

  // Map wallets to options for the dropdown, including direction
  useEffect(() => {
    if (wallets.length) {
      const options = wallets.map((wallet: any) => ({
        value: wallet.id, // Use 'id' for the value
        label: `${wallet.product} ${
          wallet.direction === "C" ? "Credit" : "Debit"
        }`, // Concatenate product with direction
      }));
      setSelectedWalletOptions(options); // Set the dropdown options
    }
  }, [wallets]);

  // Make sure you update the wallet when the dropdown changes
  const handleWalletChange = (selectedOption: any) => {
    setSelectedWallet(selectedOption);
  };

  const handlePayoutResponse = async (cbData: any) => {
    try {
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: cbData?.message ?? "",
        })
      );
      if (cbData.statusCode == "00") {
        fn();
        fn2();
        closeModal();
        return;
      } else if (cbData.statusCode == "007") {
        setIsModalOpen({ isOpened: true, data: cbData });
      } else {
        const { message } = cbData?.message || {
          message: "Unable to initiate payout at the moment",
        };
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: message,
          })
        );
      }
    } catch (error: any) {
      const { message } = error?.response?.data || {
        message: "Unable to get auth code, please try again",
      };
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  interface initTypes {
    accountNumber: string | undefined;
    description: string;
    amount: number | undefined;
    walletId: string | undefined;
  }

  const initProps: initTypes = {
    accountNumber: undefined,
    description: "",
    amount: undefined,
    walletId: undefined,
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (
      inputs.accountNumber &&
      inputs.amount &&
      !!resolveMerchantData?.data?.accountName &&
      inputs.description
    ) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  useEffect(() => {
    fetchBanks();
    fetchWallets(); // Call to fetch wallets
  }, []);

  const payoutPayload = {
    transactionType: "InternalWalletToMerchant",
    // bankCode: selectedBank?.code ?? selectedType?.value,
    bankCode: "Merchant",
    // bankId: selectedBank?.value,
    creditCurrency: selectedCurrencyInput?.shortName,
    debitCurrency: selectedCurrencyInput?.shortName,
    currencyId: selectedCurrencyInput?.value,
    // creditCurrency: selectedWallet?.value,
    // debitCurrency: selectedWallet?.value,
    // AccountName: resolveMerchantData?.data?.accountName,
    amount: inputs.amount,
    description: inputs.description,
    subsidiaryId: inputs.accountNumber,
    // accountNumber: String(inputs.walletId),
    accountNumber: inputs.accountNumber,
  };

  const initiatePayout = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post("/payout/initiate", payoutPayload);
      const message = data?.message;
      handlePayoutResponse(data?.data);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const resolveMerchant = async () => {
    if (!!inputs?.accountNumber && inputs?.accountNumber?.length < 0) return;
    dispatch(openLoader());
    try {
      const data: any = await client.post("/merchant/resolve", {
        accountNumber: inputs?.accountNumber,
        type: "Merchant",
      });
      setResolvedMerchantData(data?.data);
      const message = data?.data?.message;
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  useEffect(() => {
    if (inputs?.accountNumber) resolveMerchant();
  }, [inputs?.accountNumber]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    initiatePayout();
  };

  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Internal Wallet To Merchant</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      {active === "new" && (
        <>
          <div className={styles.businessForm}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.modalselect}>
                <label className={styles.inputLabel}>Internal Wallet</label>
                <Select
                  defaultValue={selectedWallet}
                  // onChange={handleWalletChange}
                  onChange={(selectedWallet: any) => {
                    setSelectedWallet(selectedWallet);
                    setInputs((prevState: initTypes) => {
                      const updatedState = {
                        ...prevState,
                        walletId: selectedWallet?.value,
                      };
                      return updatedState;
                    });
                  }}
                  options={selectedWalletOptions}
                  className={styles.select}
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      width: "100%",
                    }),
                  }}
                />
              </div>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="accountNumber"
                inputLabel="Merchant ID"
                inputValue={inputs.accountNumber}
              />
              {resolveMerchantData &&
                resolveMerchantData?.data?.accountName && (
                  <p>
                    Account Name:{" "}
                    <span style={{ color: "green" }}>
                      {resolveMerchantData?.data?.accountName}
                    </span>
                  </p>
                )}
              <div className={styles.modalselect}>
                <label className={styles.inputLabel}>Select Currency</label>
                <Select
                  value={selectedCurrencyInput}
                  onChange={(selectedCurrencyInput: any) => {
                    setSelectedCurrencyInput(selectedCurrencyInput);
                  }}
                  options={currenciesOption}
                  className={styles.select}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      width: "100%",
                      maxHeight: "200px", // Set a max height
                      overflowY: "auto", // Enable vertical scrolling
                    }),
                  }}
                />
              </div>

              <OutlineTextInput
                handleChange={updateProps}
                inputName="amount"
                inputLabel="Amount"
                inputValue={inputs.amount}
              />
              <OutlineTextInput
                handleChange={updateProps}
                inputName="description"
                inputLabel="Description"
                inputValue={inputs.description}
                notRequired={false}
              />
            </form>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
              Cancel
            </div>
            {disableFunction() ? (
              <div className={styles.deactivate} onClick={handleSubmit}>
                Initiate payout
              </div>
            ) : (
              <div className={styles.notClickable}> Initiate payout</div>
            )}
          </div>
        </>
      )}
      {active === "ben" && (
        <BeneficiaryPayout
          payoutCb={handlePayoutResponse}
          fn={fn}
          closeModal={closeModal}
          beneficiary={beneficiary}
        />
      )}
      <Modal
        isOpen={modalIsOpen.isOpened}
        onRequestClose={closeModal}
        contentLabel="Verify Payout"
        style={customStyles}
      >
        <PayoutVerificationModal
          data={modalIsOpen.data}
          closeModal={closePayoutVerificationModal}
        />
      </Modal>
    </div>
  );
};

export default InternalWalletToMerchantPayout;
