import React from "react";
import { PageLoader } from "../../components/page-loader/PageLoader";

const InternalWalletTransactions = React.lazy(
  () => import("./internal-wallet-transactions")
);

 const InternalWalletTransactionsPage = () => {
  return (
    <React.Suspense fallback={<PageLoader />}>
      <InternalWalletTransactions />
    </React.Suspense>
  );
};

export default InternalWalletTransactionsPage;