import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { useSessionStorage } from 'usehooks-ts';
import styles from "./DashboardRoutes.module.scss";
import SideDrawer from "../components/sidedrawer/SideDrawer";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { changeNavbar } from "../redux/actions/navbar/navbarActions";
import Dashboard from "../views/Home/Dashboard/Dashboard";
import Business from "../views/Accounts/Business";
import TopBar from "../components/topBar/TopBar";
import TransactionTab from "../views/Transactions/TransactionTab/TransactionTab";
import Businesses from "../views/Business/Businesses";
import BusinessDetails from "../views/Business/BusinessDetails/BusinessDetails";
import SettingsTab from "../views/Settings/SettingsTab/SettingsTab";
import SettlementGroupsDetails from "../views/Settings/Settlement/SettlementGroupsDetails";
import AccountTab from "../views/Accounts/AccountTab/AccountTab";
import SettlementDetails from "../views/Accounts/SettlementDetails";
import Refund from "../views/Refund/Refund";
import ApproveRefund from "../views/Refund/ApproveRefund/ApproveRefund";
import Bulklogs from "../views/Refund/Bulklogs";
import WalletTab from "../views/Wallets/TransactionTab/WalletTab";
import CreditDetails from "../views/Wallets/Credit/CreditDetails";
import DebitDetails from "../views/Wallets/Debit/DebitDetails";
import ChargeBack from "../views/Chargeback/Chargeback";
import BulklogsChargeback from "../views/Chargeback/BulklogsChargeback";
import SettlementTab from "../views/Settlement/Settlements/SettlementTab/SettlementTab";
import SettlementsDetails from "../views/Settlement/Settlements/SettlementDetails";
import Reconciliation from "../views/Settlement/Reconciliation/ReconciliationTab/ReconciliationTab";
import PendingSettlementApproval from "../views/Settlement/PendingSettlementApproval/PendingSettlementApproval";
import Report from "../views/Settlement/Reports/Report";
import Sales from "../views/Risk&Fraud/Sales/Sales";
import ChargebackBreakdown from "../views/Risk&Fraud/ChargebackBreakdown/ChargebackBreakdown";
import Breakdown from "../views/Risk&Fraud/ChargebackBreakdown/breakdownDetails/Breakdown";
import FraudRatio from "../views/Risk&Fraud/Fraud Ratio/FruadRatio";
import AudioTrail from "../views/Risk&Fraud/AudioTrail/AudioTrail";
import Assessment from "../views/Risk&Fraud/Assessment/Assessment";
import FraudTab from "../views/Risk&Fraud/FraudTab/FraudTab";
import Fees from "../views/Fee&limit/Fees/Fees";
import FeesTab from "../views/Fee&limit/FeesTab/FeesTab";
import LimitTab from "../views/Fee&limit/LimitTab/LimitTab";
import Provider from "../views/Fee&limit/Provider/Provider";
import ApprovalTab from "../views/Fee&limit/ApprovalTab/ApprovalTab";
import SideDrawerMobile from "../components/sidedrawermobile/SideDrawerMobile";
import Channel from "../views/Channels/Channel";
import NotFound from "../views/NotFound/NotFound";
import BaseBasicGroupView from "../views/Groups/BaseBasicGroup/BaseBasicGroup";
import SettlementsApproved from "../views/Settlement/Settlements/SettlementsApproved";
import ChargebackDetails from "../views/Chargeback/ChargebackDetails/ChargebackDetails";
import SettlementPlan from "../views/Fee&limit/SettlementPlans/SettlementPlan";
import BusinessModuleDetails from "../views/Accounts/BusinessModuleDetails";
import Fundinghistory from "../views/Accounts/Funding/Fundinghistory";
import BalanceHistory from "../views/Accounts/BalanceHistory/BalanceHistory";
import RollingReserve from "../views/Accounts/RollingReserves/RollingReserve";
import Payouts from "../views/Payout/Payouts/Payouts";
import InitiatePayouts from "../views/Payout/InitiatePayouts/InitiatePayouts";
import ApprovePayouts from "../views/Payout/ApprovePayouts/ApprovePayouts";
import BalanceOperation from "../views/Accounts/BalanceOperation/BalanceOperation";
import InitiateBankToBankPayouts from "../views/Payout/InitiateBankToBankPayouts/InitiateBankToBankPayouts";
import ApproveBankToBankPayouts from "../views/Payout/ApproveBankToBankPayouts/ApproveBankToBankPayouts";
import InternalWalletPage from "../views/InternalWallets";
import InternalWalletTransactionsPage from "../views/InternalWalletTransactions";
import UsdPayouts from "../views/Payout/UsdPayouts/UsdPayouts";

export default function DashboardRoutes() {
  const _selectedMenu = Number(sessionStorage.getItem("selectedMenu") ?? 1);
  const setSelectedMenu = (item: any) => {
    sessionStorage.setItem("selectedMenu", item);
    setSelectedMenu_(item);
  };
  const _selectedNav = Number(sessionStorage.getItem("selectedNav") ?? 1);
  const setSelectedNav = (item: any) => {
    sessionStorage.setItem("selectedNav", item);
    setSelectedNav_(item);
  };
  const [selectedMenu, setSelectedMenu_] = useState(_selectedMenu);
  const [selectedNav, setSelectedNav_] = useState(_selectedNav);
  // console.log(selectedMenu, selectedNav)
  const [collapse, setCollapse] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 920);
  const [switchy, setSwitchy] = useState(false);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 920);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const openMenu = ({ url }: any) => {
    history.push(url);
  };

  return (
    <Router>
      <div className={styles.wrapper}>
        <div
          style={{ display: !isDesktop ? "none" : "" }}
          className={collapse ? styles.sidedrawerSwitch : styles.sidedrawer}
        >
          <SideDrawer
            selectedMenu={selectedMenu}
            selectedNav={selectedNav}
            setSelectedMenu={setSelectedMenu}
            setSelectedNav={setSelectedNav}
            collapse={collapse}
            setCollapse={setCollapse}
          />
        </div>
        <div
          style={{ display: switchy ? "block" : "none" }}
          className={styles.newsidedrawer}
        >
          <SideDrawerMobile
            selectedMenu={selectedMenu}
            selectedNav={selectedNav}
            setSelectedMenu={setSelectedMenu}
            setSelectedNav={setSelectedNav}
            collapse={switchy}
            setCollapse={setSwitchy}
          />
        </div>
        <div className={styles.routerContainer}>
          <TopBar
            selectedMenu={selectedMenu}
            selectedNav={selectedNav}
            setSelectedMenu={setSelectedMenu}
            setSelectedNav={setSelectedNav}
            collapse={switchy}
            setCollapse={setSwitchy}
          />
          <div
            className={
              collapse ? styles.switchContainer : styles.switchContainerP
            }
          >
            <Switch>
              <Route exact path="/dashboard/">
                <Dashboard />
              </Route>
              <Route
                exact
                path="/dashboard/businesses"
                component={Businesses}
              />
              <Route exact path="/dashboard/channels" component={Channel} />
              <Route
                exact
                path="/dashboard/business/:id"
                component={BusinessDetails}
              />
              <Route
                exact
                path="/dashboard/transactions"
                component={TransactionTab}
              />
              <Route exact path="/dashboard/payouts" component={Payouts} />
              <Route
                exact
                path="/dashboard/payouts/initiate"
                component={InitiatePayouts}
              />
              <Route
                exact
                path="/dashboard/payouts/approve"
                component={ApprovePayouts}
              />
              <Route
                exact
                path="/dashboard/payouts/Initiate-Bank-To-Bank-Payouts"
                component={InitiateBankToBankPayouts}
              />
              <Route
                exact
                path="/dashboard/payouts/Approve-Bank-To-Bank-Payouts"
                component={ApproveBankToBankPayouts}
              />
              <Route
                exact
                path="/dashboard/payouts/Usd-Payouts"
                component={UsdPayouts}
              />
              <Route exact path="/dashboard/accounts" component={AccountTab} />
              <Route
                exact
                path="/dashboard/accounts/:id/business-details"
                component={BusinessModuleDetails}
              />
              <Route
                exact
                path="/dashboard/accounts/:id/funding-history"
                component={Fundinghistory}
              />
              <Route
                exact
                path="/dashboard/accounts/:id/wallet-history"
                component={BalanceHistory}
              />
              <Route
                exact
                path="/dashboard/accounts/:id/rolling-reserve"
                component={RollingReserve}
              />
              <Route
                exact
                path="/dashboard/accounts/:id/balance-operation"
                component={BalanceOperation}
              />
              <Route exact path="/dashboard/settings" component={SettingsTab} />
              <Route
                exact
                path="/dashboard/settings/settlement-groups/:id"
                component={SettlementGroupsDetails}
              />
              <Route
                exact
                path="/dashboard/accounts/settlement-details/:id"
                component={SettlementDetails}
              ></Route>
              <Route exact path="/dashboard/refunds" component={Refund}></Route>
              <Route
                exact
                path="/dashboard/refunds/approval"
                component={ApproveRefund}
              ></Route>
              <Route
                exact
                path="/dashboard/refunds/bulklogs"
                component={Bulklogs}
              ></Route>
              <Route
                exact
                path="/dashboard/wallets"
                component={WalletTab}
              ></Route>
              <Route
                exact
                path="/dashboard/wallets/credit/:id"
                component={CreditDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/wallets/debit/:id"
                component={DebitDetails}
              ></Route>
              {/* Internal Wallets */}
              <Route
                exact
                path="/dashboard/internal-wallets"
                component={InternalWalletPage}
              />
              <Route
                exact
                path="/dashboard/internal-wallets/:walletId/wallet-transactions/:currency"
                component={InternalWalletTransactionsPage}
              />
              <Route
                exact
                path="/dashboard/chargebacks"
                component={ChargeBack}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/:id/details"
                component={ChargebackDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/chargeback/bulklogs"
                component={BulklogsChargeback}
              ></Route>
              <Route
                exact
                path="/dashboard/settlements"
                component={SettlementTab}
              ></Route>
              <Route
                exact
                path="/dashboard/settlements/approved"
                component={() => SettlementsApproved()}
              ></Route>
              <Route
                exact
                path="/dashboard/settlements/pending/approval"
                component={() => SettlementsApproved("pending")}
              ></Route>
              <Route
                exact
                path="/dashboard/settlements/details/:id"
                component={SettlementsDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/settlements/reconciliations"
                component={Reconciliation}
              ></Route>
              <Route
                exact
                path="/dashboard/settlements/pendingapproval"
                component={PendingSettlementApproval}
              ></Route>
              <Route
                exact
                path="/dashboard/settlements/reports"
                component={Report}
              ></Route>
              <Route exact path="/dashboard/fruad" component={Sales}></Route>
              <Route
                exact
                path="/dashboard/settlements/settlement-plans"
                component={SettlementPlan}
              ></Route>
              <Route exact path="/dashboard/fruad" component={Sales}></Route>
              <Route
                exact
                path="/dashboard/fruad/chargeback_breakdown"
                component={ChargebackBreakdown}
              ></Route>
              <Route
                exact
                path="/dashboard/fruad/chargeback_breakdown"
                component={Breakdown}
              ></Route>
              <Route
                exact
                path="/dashboard/fruad/cb_fraudratio"
                component={FraudRatio}
              ></Route>
              <Route
                exact
                path="/dashboard/fruad/audittrails"
                component={AudioTrail}
              ></Route>
              <Route
                exact
                path="/dashboard/fruad/assessment_fee"
                component={Assessment}
              ></Route>
              <Route
                exact
                path="/dashboard/fruad/cb_fraudsummary"
                component={FraudTab}
              ></Route>
              <Route exact path="/dashboard/fees" component={FeesTab}></Route>
              <Route
                exact
                path="/dashboard/fees/limits"
                component={LimitTab}
              ></Route>

              <Route
                exact
                path="/dashboard/fees/providers"
                component={Provider}
              ></Route>
              <Route
                exact
                path="/dashboard/fees/approvals"
                component={ApprovalTab}
              ></Route>
              <Route
                exact
                path="/dashboard/groups/pricings"
                component={() => <BaseBasicGroupView name="Pricing" />}
              ></Route>
              <Route
                exact
                path="/dashboard/groups/subsidiaries"
                component={() => <BaseBasicGroupView name="Subsidiary" />}
              ></Route>
              <Route
                exact
                path="/dashboard/groups/settlements"
                component={() => <BaseBasicGroupView name="Settlement" />}
              ></Route>
              <Route
                exact
                path="/dashboard/groups/limits"
                component={() => <BaseBasicGroupView name="Limit" />}
              ></Route>
              <Route
                exact
                path="/dashboard/groups/payment-options"
                component={() => <BaseBasicGroupView name="Payment Option" />}
              ></Route>
              <Route exact path="*" component={NotFound}></Route>
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}
