import React, { useEffect, useState } from "react";
import styles from "./Business.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/closenew.svg";
import OutlineTextInput from "../../../components/input/OutlineTextInput";
import { Divider, Input, Select } from "antd";
import client from "../../../api/client";
import { useDispatch, useSelector } from "react-redux";
import {
  getMccsAction,
  getSubsidiaryGroupsAction,
  getAccountManagersAction,
  getReferralIdsAction,
} from "../../../redux/actions/appSettings/appSettings";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import report from "../../../api/report";
import BusinessRegistration from "./BusinessRegistration/BusinessRegistration";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import BusinessInformation from "./BusinessInformation/BusinessInformation";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../../constants/toastStyles";

function UpdateDetailModal({
  closeModal,
  fieldName,
  currentValue,
  id,
  isFullScreen,
}: any) {
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const {
    mccs,
    subsidiaryGroups,
    subsidiaryStatuses,
    accountManagers,
    referralIds,
  } = useSelector((state) => state.appSettingsReducer);
  function closeModalSingle(message?: string, err?: string) {
    closeModal(message, err);
  }

  const [inputs, setInputs] = React.useState<any>({
    value: currentValue,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState("mccCode");

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs({
      ...inputs,
      value: newValue,
    });
  };

  const options = (() => {
    let value;
    switch (fieldName) {
      case "subsidiaryGroupId":
        value = subsidiaryGroups;
        break;
      case "status":
        value = subsidiaryStatuses;
        break;
      // case "AccountManagerId": value = accountManagers; break;
      // case "ReferralId": value = referralIds; break;
      case "AccountManager":
        value = accountManagers ?? [];
        break;
      case "ReferralId":
        value = [
          { value: 101, name: "Referral 1" },
          { value: 102, name: "Referral 2" },
        ];
        break;
      default:
        value = mccs;
        break;
    }
    return value;
  })();

  const filteredOptions = options.filter(
    (option: {
      name: { split: (arg0: string) => [any]; toLowerCase: () => any };
    }) => {
      const [mccCode] = option.name.split(" - "); // Extract MCC Code before "-"
      const mccDescription = option.name.toLowerCase(); // Full description

      const mccCodeMatch = mccCode.includes(searchTerm); // Match MCC Code
      const descriptionMatch = mccDescription.includes(
        searchTerm.toLowerCase()
      ); // Match Description

      if (searchType === "mccCode") return mccCodeMatch;
      if (searchType === "description") return descriptionMatch;
      return mccCodeMatch || descriptionMatch; // Default: Both
    }
  );

  const mapMccs = (mccs: any[]) => {
    const dd: any[] = [];
    mccs.map((x) => {
      dd.push({ value: x.id, name: `${x.mcc} - ${x.description}` });
    });
    dispatch(getMccsAction(dd));
    // setOptions([...dd]);
  };

  const mapAccountManagers = (managers: any[]) => {
    const formattedManagers = managers.map((x) => ({
      value: x.adminUserId,
      name: x.name,
    }));
    dispatch({
      type: "GET_ACCOUNT_MANAGERS",
      accountManagers: formattedManagers,
    });
  };

  const mapReferralIds = (referrals: any[]) => {
    const dd = referrals.map((x) => ({ value: x.id, name: x.name }));
    dispatch({ type: "SET_REFERRAL_IDS", payload: dd });
  };

  const mapSubsidiaryGroups = (mccs: any[]) => {
    const dd: any[] = [];
    mccs.map((x) => {
      dd.push({ value: x.id, name: `${x.name}` });
    });
    dispatch(getSubsidiaryGroupsAction(dd));
    // setOptions([...dd]);
  };
  const getMccs = () => {
    if (mccs.length < 1) {
      dispatch(openLoader());
      client
        .get(`/mcc/all`)
        .then((data: any) => {
          mapMccs(data?.data?.merchantCategoryCodes ?? []);
        })
        .catch((err: any) => {})
        .finally(() => {
          dispatch(closeLoader());
        });
    }
  };

  const getAccountManagers = async () => {
    try {
      dispatch(openLoader());
      const url = `/users/all/minimal`;

      const { data }: any = await client.get(url);
      // Use real data or empty array if no data
      mapAccountManagers(data?.data || []);
    } catch (error: any) {
      console.error(
        "Error fetching account managers:",
        error?.response?.data?.message || error.message
      );

      dispatch(
        openToastAndSetContent({
          toastContent:
            error?.response?.data?.message ||
            "Failed to fetch account managers",
          toastStyles: ToastErrorStyles,
        })
      );

      // If an error occurs, set an empty array to avoid breaking anything
      mapAccountManagers([]);
    } finally {
      dispatch(closeLoader());
    }
  };

  const getReferralIds = () => {
    if (!referralIds?.length) {
      console.log("Fetching mock referral IDs...");
      const mockReferrals = [
        { id: 101, name: "Referral 1" },
        { id: 102, name: "Referral 2" },
      ];
      mapReferralIds(mockReferrals); // Use mock data
    }
  };

  // const getReferralIds = () => {
  //   if (!referralIds?.length) {
  //     dispatch(openLoader());
  //     client.get(`/referrals/all`).then((data: any) => {
  //       mapReferralIds(data?.data ?? []);
  //     }).catch((err: any) => {
  //       console.error(err);
  //     }).finally(() => {
  //       dispatch(closeLoader());
  //     });
  //   }
  // };

  const getSubsidiayGroups = () => {
    if (mccs.length < 1) {
      dispatch(openLoader());
      report
        .getSubsidiaryGroups()
        .then((res: any) => {
          mapSubsidiaryGroups(res?.data?.data ?? []);
        })
        .catch((err: any) => {})
        .finally(() => {
          dispatch(closeLoader());
        });
    }
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    setInputs({ value: "" });

    // 🔹 Call extra API for Account Manager before existing API logic
    if (fieldName === "AccountManager") {
      client
        .post(`/kyc/subsidiary/account-manager`, {
          subsidiaryId: id,
          accountManagerId: inputs.value,
        })
        .then((response: any) => {
          console.log("Account manager updated successfully:", response.data);
          closeModalSingle(
            response?.data?.message ?? "Account Manager updated successfully",
            ""
          );
        })
        .catch((error: any) => {
          closeModalSingle(
            "",
            error.response?.data?.message ?? "Failed to update account manager"
          );
        });
    } else {
      client
        .post(`subsidiary/update`, {
          subsidiaryId: id,
          [fieldName]: inputs.value,
        })
        .then((data: any) => {
          closeModalSingle(
            data?.data?.message ?? "Merchant information updated successfully",
            ""
          );
        })
        .catch((err: any) => {
          closeModalSingle(
            "",
            err.response?.data?.message ??
              "Unable to update merchant information"
          );
        });
    }
  };

  useEffect(() => {
    switch (fieldName) {
      case "subsidiaryGroupId":
        getSubsidiayGroups();
        break;
      case "AccountManager":
        getAccountManagers();
        break;
      case "ReferralId":
        getReferralIds();
        break;
      case "status":
        break;
      case "mccid":
      default:
        getMccs();
        break;
    }
  }, []);
  const disabledFunction = () => {
    if (inputs.value === "" || options.length < 1) {
      return true;
    } else {
      return false;
    }
  };
  const getModalTitle = () => {
    switch (fieldName) {
      case "subsidiaryGroupId":
        return "Subsidiary Group";
      case "status":
        return "Update Merchant Status";
      case "AccountManager":
        return "Account Manager";
      case "ReferralId":
        return "Referral Id";
      case "business_registration":
        return "Business Registration";
      case "personal_information":
        return "Personal Information";
      case "business_information":
        return "Business Information";
      case "mccid":
      default:
        return "Update Merchant MCC";
    }
  };

  const DefaultContent = (
    <div className={styles.input}>
      <OutlineTextInput
        handleChange={updateProps}
        inputName={fieldName}
        inputLabel={fieldName}
        inputValue={inputs.value}
        inputSelect
        // inputOption={options}
        inputOption={filteredOptions}
        style={{ width: "100%" }}
      />
    </div>
  );

  const MCCContent = (
    <>
      <Select
        value={searchType}
        onChange={(value) => setSearchType(value)}
        style={{
          width: "50%",
          borderRadius: "8px",
        }}
      >
        <Select.Option value="description">Search by Name</Select.Option>
        <Select.Option value="mccCode">Search by MCC</Select.Option>
      </Select>

      {/* Search Input */}
      <Input
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          borderRadius: "8px",
          padding: "8px",
          margin: "10px 0px",
          fontSize: "16px",
        }}
      />

      {DefaultContent}
    </>
  );
  const getModalContent = () => {
    switch (fieldName) {
      case "business_registration":
        return <BusinessRegistration subsidiaryId={id} cb={closeModal} />;
      case "personal_information":
        return <PersonalInformation subsidiaryId={id} cb={closeModal} />;
      case "business_information":
        return <BusinessInformation subsidiaryId={id} cb={closeModal} />;
      case "mccid": // <-- Check if MCC should be shown
        return MCCContent;
      default:
        return DefaultContent;
    }
  };

  return (
    <div
      className={isFullScreen ? styles.modalwrapperFull : styles.modalwrapper}
    >
      <div className={styles.modalhead}>
        <h3 className={styles.modalheadh3}>{getModalTitle()}</h3>
        <CloseIcon onClick={() => closeModal()} style={{ cursor: "pointer" }} />
      </div>
      <Divider style={{ margin: 0, padding: 0 }} />
      <div className={styles.modalcontent}>
        {getModalContent()}
        <Divider style={{ margin: 0, padding: 0 }} />

        {!isFullScreen && (
          <div className={styles.modalFooter}>
            <button className={styles.cancel} onClick={() => closeModal()}>
              Cancel
            </button>
            <button
              disabled={disabledFunction()}
              onClick={handleSubmit}
              className={styles.fund}
            >
              Update
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default UpdateDetailModal;
