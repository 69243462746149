import React, { Dispatch, useEffect, MouseEventHandler } from "react";
import styles from "./FeesModal.module.scss";
import client from "../../../api/client";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import { numberWithCommas } from "../../../utils/formatNumber";
import { Divider } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Grid } from "@material-ui/core";
import * as Yup from "yup";
import { InputLabel, TextField } from "@material-ui/core";
import SelectWrapper from "../../../components/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import Mark from "../../../assets/images/Icons/u_save.svg";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { makeStyles } from "@material-ui/core";
import useApi from "../../../hooks/useApi";
import reports from "../../../api/report";

type FeeModalProps = {
  closeModal: Function | any;
  cb: Function | any;
  data?: any;
};
const FeesModal = ({ closeModal, data, cb }: FeeModalProps) => {
  const getPaymentMethods = useApi(reports.getPaymentMethods);
  const getPricingGroups = useApi(reports.getPricingGroups);

  const isUpdate = !!data;
  // console.log({ datadd: data });
  useEffect(() => {
    getPaymentMethods.request();
    getPricingGroups.request();
  }, []);

  const dispatch = useDispatch();
  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-input.MuiOutlinedInput-input":
        {
          textAlign: "center",
          padding: "8.1px 14px",
        },
    },
    select: {
      "& .MuiOutlinedInput-root": {
        color: "#414141",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        borderRadius: "10px",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        outline: "none",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        backgroundColor: "#ffffff",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "#E0E0E0",
        backgroundColor: "#ffffff",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #E0E0E0",
      },
      "& .Mui-disabled": {
        opacity: "0.7",
        cursor: "not-allowed",
      },
    },
  });
  const classes = useStyles();
  const { currencies } = useSelector((state) => state.appSettingsReducer);
  const currencyOption = currencies.map((curr: any) => ({
    name: `${curr.name} (${curr.shortName})`,
    val: curr.shortName,
  }));
  const validate = Yup.object({
    fee: Yup.number().required("Required").min(0),
    paymentOption: Yup.string().required("Required"),
    pricingGroupId: Yup.string().required("Required"),
    cap: Yup.string().required("Required"),
    // flatValue: Yup.string().required('Required'),
    currency: Yup.string().required("Required"),
  });

  // const [currencyOption, setCurrencyOption] = useState([{ name: 'USD' }, { name: 'NGN' }]);
  // console.log({ getPaymentMethods });
  const mapPaymentOptions = () =>
    (getPaymentMethods?.data?.data ?? []).map((x: any) => {
      return { name: x.name, val: x.code };
    });
  const paymeth = mapPaymentOptions();
  const mapPricingGroups = () =>
    (getPricingGroups?.data?.data ?? []).map((x: any) => {
      return { name: x.name, val: x.id };
    });
  const pricingGroupOptions = mapPricingGroups();

  const submitHandler = (values: any) => {
    dispatch(openLoader());
    const payload = {
      currency: values.currency,
      paymentOption: isUpdate ? data?.paymentOptionCode : values.paymentOption,
      fee: Number(values.fee) / 100,
      cap: Number(values.cap) ?? 0,
      flatValue: values.flatValue ?? 0,
      internationalFee: (Number(values.internationalFee) ?? 0) / 100,
      internationalCap: values.internationalCap,
      internationalFlatValue: values.internationalFlatValue,
      pricingGroupId: isUpdate ? data?.pricingGroupId : values.pricingGroupId,
      minimumFee: values.minimumFee,
      minimumFeeInternational: values.minimumFeeInternational,
      chargebackFee: values.chargebackFee,
      refundFee: values.refundFee ?? 0,
    };
    client
      .post(`pricing/collection/add`, payload)
      .then((res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        closeModal();
        cb();
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data ?? {};
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };
  return (
    <div className={styles.modalwrapper}>
      <div className={styles.modalhead}>
        <h3 className={styles.modalheadh3}>
          {data ? "Update Fee" : "Add a fee"}
        </h3>
        <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
      </div>
      <Divider style={{ margin: 0, padding: 0 }} />
      <Formik
        enableReinitialize
        initialValues={{
          paymentOption: isUpdate
            ? data?.paymentOption
            : data?.paymentOptionCode ?? "",
          fee: Number(data?.fee ?? 0),
          cap: data?.cap,
          flatValue: data?.flatValue ?? "",
          internationalFee: data?.internationalFee ?? 0,
          internationalCap: data?.internationalCap ?? "",
          internationalFlatValue: data?.internationalFlatValue ?? "",
          currency: data?.currency ?? "",
          pricingGroupId: isUpdate ? data?.pricingGroup : data?.pricingGroupId,
          minimumFee: data?.minimumFee ?? 0,
          minimumFeeInternational: data?.minimumFeeInternational ?? 0,
          chargebackFee: data?.chargebackFee ?? 0,
          refundFee: data?.refundFee ?? 0,
        }}
        validationSchema={validate}
        onSubmit={submitHandler}
      >
        {(props) => (
          <div
            style={{
              padding: "32px 24px",
            }}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Payment Method</span>
                    </InputLabel>
                    <Field
                      as={isUpdate ? TextField : SelectWrapper}
                      disabled={!!isUpdate}
                      helperText={
                        <ErrorMessage name="paymentOption">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="paymentOption"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      options={paymeth}
                      className={classes.select}
                      placeholder="Payment Method"
                      // defaultValue={paymeth && paymeth[0]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Pricing Group</span>
                    </InputLabel>
                    <Field
                      as={isUpdate ? TextField : SelectWrapper}
                      disabled={!!isUpdate}
                      helperText={
                        <ErrorMessage name="pricingGroupId">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="pricingGroupId"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      options={pricingGroupOptions}
                      className={classes.select}
                      placeholder=""
                      // defaultValue={pricingGroupOptions && pricingGroupOptions[0]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Currency</span>
                    </InputLabel>
                    <Field
                      as={isUpdate ? TextField : SelectWrapper}
                      disabled={!!isUpdate}
                      helperText={
                        <ErrorMessage name="currency">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="currency"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      options={currencyOption}
                      className={classes.select}
                      placeholder="Currency"
                      // defaultValue={currencyOption && currencyOption[0]}
                    />
                  </div>
                </Grid>
                {/* Fee */}
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Fee (%)</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      onChange={(e: { target: { value: any } }) =>
                        props.setFieldValue("fee", e.target.value)
                      }
                      helperText={
                        <ErrorMessage name="fee">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="fee"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {/* Cap */}
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Cap</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      onChange={(e: { target: { value: any } }) =>
                        props.setFieldValue("cap", e.target.value)
                      }
                      helperText={
                        <ErrorMessage name="cap">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="cap"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {/* Intl Fee */}
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>
                        International Fee (%)
                      </span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      onChange={(e: { target: { value: any } }) => {
                        console.log({ val: e.target.value });

                        props.setFieldValue("internationalFee", e.target.value);
                      }}
                      helperText={
                        <ErrorMessage name="internationalFee">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="internationalFee"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {/* Intl Cap */}
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>International Cap</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      onChange={(e: { target: { value: any } }) =>
                        props.setFieldValue("internationalCap", e.target.value)
                      }
                      helperText={
                        <ErrorMessage name="internationalCap">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="internationalCap"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {/* Minimum Fee Value */}
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Minimum Fee Value</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      onChange={(e: { target: { value: any } }) =>
                        props.setFieldValue("minimumFee", e.target.value)
                      }
                      helperText={
                        <ErrorMessage name="minimumFee">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="minimumFee"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {/* Minimum Fee International Value */}
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>
                        Minimum Fee Intl. Value
                      </span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      onChange={(e: { target: { value: any } }) =>
                        props.setFieldValue(
                          "minimumFeeInternational",
                          e.target.value
                        )
                      }
                      helperText={
                        <ErrorMessage name="minimumFeeInternational">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="minimumFeeInternational"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {/* Chargeback Fee Value */}
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Chargeback Fee Value</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      onChange={(e: { target: { value: any } }) =>
                        props.setFieldValue("chargebackFee", e.target.value)
                      }
                      helperText={
                        <ErrorMessage name="chargebackFee">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="chargebackFee"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {/* Refund Fee Value */}
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Refund Fee Value</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      onChange={(e: { target: { value: any } }) =>
                        props.setFieldValue("refundFee", e.target.value)
                      }
                      helperText={
                        <ErrorMessage name="refundFee">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="refundFee"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {/* Flat Value */}
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>Flat Value</span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      onChange={(e: { target: { value: any } }) =>
                        props.setFieldValue("flatValue", e.target.value)
                      }
                      helperText={
                        <ErrorMessage name="flatValue">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="flatValue"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {/* Intl Flat Value */}
                <Grid item xs={12} sm={6}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>
                        International Flat Value
                      </span>
                    </InputLabel>
                    <Field
                      as={TextField}
                      onChange={(e: { target: { value: any } }) =>
                        props.setFieldValue(
                          "internationalFlatValue",
                          e.target.value
                        )
                      }
                      helperText={
                        <ErrorMessage name="internationalFlatValue">
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name="internationalFlatValue"
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                </Grid>
                {/* Submit Button */}
                <Grid item xs={12}>
                  <button type="submit" className={styles.Downloadbutton}>
                    <span className={styles.Downloadbutton_span}>
                      <img src={Mark} alt="" />
                    </span>
                    Add Fee
                  </button>
                </Grid>{" "}
              </Grid>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default FeesModal;
