import React from "react";

export interface PayoutsData {
  dateInitiated: string;
  recipientName: string;
  merchantName: string;
  amount: string;
  fee: string;
  subsidiaryId: string;
  accountNumber: string;
  currency: string;
  type: string;
  transferType: string;
  action: React.ReactNode;
  status: React.ReactNode;
}

export const ColumnPayouts = [
  {
    Header: "Payout date",
    accessor: "dateInitiated",
  },
  {
    Header: "Merchant",
    accessor: "merchantName",
  },
  {
    Header: "Merchant ID",
    accessor: "subsidiaryId",
  },
  {
    Header: "Recipient",
    accessor: "recipientName",
  },
  {
    Header: "Beneficiary Number",
    accessor: "accountNumber",
  },
  // {
  //   Header: "Currency",
  //   accessor: "currency",
  // },
  {
    Header: "Total amount",
    accessor: "amount",
  },
  {
    Header: "Transfer Type",
    accessor: "transferType",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  // {
  //   Header: "Settled amount",
  //   accessor: "fee",
  // },
  {
    Header: "Fee",
    accessor: "fee",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
export interface UsdPayoutsData {
  dateInitiated: string;
  // recipientName: string;
  // merchantName: string;
  amount: string;
  fee: string;
  subsidiaryId: string;
  accountNumber: string;
  currency: string;
  // type: string;
  transferType: string;
  action: React.ReactNode;
  status: React.ReactNode;
}
export const UsdColumnPayouts = [
  {
    Header: "Payout date",
    accessor: "dateInitiated",
  },
  // {
  //   Header: "Merchant",
  //   accessor: "merchantName",
  // },
  {
    Header: "Merchant ID",
    accessor: "subsidiaryId",
  },
  // {
  //   Header: "Recipient",
  //   accessor: "recipientName",
  // },
  {
    Header: "Beneficiary Number",
    accessor: "accountNumber",
  },
  // {
  //   Header: "Currency",
  //   accessor: "currency",
  // },
  {
    Header: "Total amount",
    accessor: "amount",
  },
  {
    Header: "Transfer Type",
    accessor: "transferType",
  },
  // {
  //   Header: "Type",
  //   accessor: "type",
  // },
  // {
  //   Header: "Settled amount",
  //   accessor: "fee",
  // },
  {
    Header: "Fee",
    accessor: "fee",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
export interface BankToBankPayoutsData {
  dateInitiated: string;
  recipientName: string;
  bankName: string;
  amount: string;
  fee: string;
  subsidiaryId: string;
  accountNumber: string;
  currency: string;
  type: string;
  transferType: string;
  action: React.ReactNode;
  status: React.ReactNode;
}

export const ColumnBankToBankPayouts = [
  {
    Header: "Date Payout Initiated",
    accessor: "dateInitiated",
  },
  {
    Header: "Bank Name",
    accessor: "bankName",
  },
  {
    Header: "Recipient",
    accessor: "recipientName",
  },
  {
    Header: "Beneficiary Number",
    accessor: "accountNumber",
  },
  // {
  //   Header: "Currency",
  //   accessor: "currency",
  // },
  {
    Header: "Total amount",
    accessor: "amount",
  },
  // {
  //   Header: "Transfer Type",
  //   accessor: "transferType",
  // },
  // {
  //   Header: "Type",
  //   accessor: "type",
  // },
  // {
  //   Header: "Settled amount",
  //   accessor: "fee",
  // },
  // {
  //   Header: "Fee",
  //   accessor: "fee",
  // },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
