import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const exportToXLS = (
  apiData: any,
  fileName: string,
  type: XLSX.BookType = "xlsx"
) => {
  if (type === "csv") {
    const csvFileType = "text/csv;charset=utf-8;";
    const csvFileExtension = ".csv";
    const ws = XLSX.utils.json_to_sheet(apiData);
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: csvFileType });
    FileSaver.saveAs(blob, fileName + csvFileExtension);
    return;
  }
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: type, type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
