import { useEffect, useState } from "react";
import styles from "./Refund.module.scss";
import { ReactComponent as CloseIcon } from "../../assets/images/Icons/closenew.svg";
import { Divider } from "antd";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../constants/toastStyles";
import { useDispatch } from "react-redux";
import MultipleUpload from "../../components/multipleUpload/MultipleUpload";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";

import bulkSampleFile from "../../assets/documents/bulk-refund-sheet.xlsx";
import { useMutation } from "@tanstack/react-query";

function Bulk({ closeModal, fn, openModal }: any) {
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch();
  const { mutate, isLoading, isError, error } = useMutation({
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append("file", file);

      const response: any = await client.post("refund/bulk", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const message = response?.data?.message;
      closeModal();
      fn && fn();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    },
  });

  useEffect(() => {
    if (!isLoading) {
      dispatch(closeLoader());
    }
  }, [isLoading, dispatch]);

  const uploadMultipleFile = async () => {
    if (!file) return window.alert("please, add a refund");

    dispatch(openLoader());
    try {
      mutate(file);
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(closeLoader());
      console.log({ error });

      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message ?? "something went wrong",
        })
      );
      openModal();
    }
  };

  const saveFile = (file?: File) => {
    if (!file) {
      return;
    }

    setFile(file);
  };

  return (
    <div className={styles.modalwrapper}>
      <div className={styles.modalhead}>
        <h3 className={styles.modalheadh3}>Log bulk refund</h3>
        <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
      </div>
      {isError ? (
        <div className={styles.modalcontent}>
          <h3>Error Processing File</h3>
          <p>
            {(error as any)?.response?.data?.message ?? "Refund upload failed"}
          </p>
          {(error as any)?.response?.data?.errors && (
            <>
              {
                <table>
                  <thead>
                    <tr>
                      <th>Reference</th>
                      <th>Errors</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries((error as any)?.response?.data?.errors).map(
                      ([key, messages]: any) => (
                        <tr key={key}>
                          <td>{key}:</td>
                          <td>
                            <ul>
                              {messages.map((msg: any, index: any) => (
                                <li key={index}>{msg}</li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              }
            </>
          )}
        </div>
      ) : (
        <>
          <Divider style={{ margin: 0, padding: 0 }} />
          <div className={styles.modalcontent}>
            <MultipleUpload
              onDrop={(result: any) => {
                //   setFile(result);
              }}
              saveFile={saveFile}
            />
            {/* <input
						type='file'
						name='avatar'
						id='file'
						accept='.csv, text/csv'
						onChange={handleChange}
						ref={ref}
						hidden
					/> */}

            {/* <p>Upload the refund CSV file to begin</p> */}

            <p className={styles.modaluploadp}>
              Download sample Excel file for bulk refund &nbsp;
              <span className={styles.sampleDownload}>
                <a
                  // href='https://docs.google.com/spreadsheets/d/1YirZkfRIYFBgV3SpuE0wtKCgxl9hpvHRhhrRh9fPKog/edit?usp=sharing'
                  // rel='noreferrer'
                  // target='_blank'
                  href={bulkSampleFile}
                  download
                >
                  here...
                </a>
              </span>
            </p>
            <Divider style={{ margin: 0, padding: 0 }} />

            <div className={styles.modalFooter}>
              <button className={styles.cancel} onClick={closeModal}>
                Cancel
              </button>

              <button onClick={uploadMultipleFile} className={styles.fund}>
                Continue
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Bulk;
