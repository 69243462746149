import React, { Dispatch, useEffect, MouseEventHandler, useState } from "react";
import styles from "./UpdateSubsidiaryConfigModal.module.scss";
import client from "../../../api/client";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import { numberWithCommas } from "../../../utils/formatNumber";
import { Divider } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Grid } from "@material-ui/core";
import * as Yup from "yup";
import { InputLabel, TextField } from "@material-ui/core";
import SelectWrapper from "../../Select";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import Mark from "../../../assets/images/Icons/u_save.svg";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { makeStyles } from "@material-ui/core";
import useApi from "../../../hooks/useApi";
import reports from "../../../api/report";
import subsidiaryApi from "../../../api/subsidiaries";
import { useModalStyles } from "./styles";
import {
  formatConfigPayload,
  formatLimitValueToForm,
} from "./payloadFormatter";
import UpdateSettlementGroup from "./UpdateSettlementGroup";

type UpdateSubsidiaryConfigModalProps = {
  closeModal: Function | any;
  cb: Function | any;
  subsidiary?: any;
  groupInformation?: any;
};
const UpdateSubsidiaryConfigModal = ({
  closeModal,
  subsidiary,
  groupInformation,
  cb,
}: UpdateSubsidiaryConfigModalProps) => {
  const dispatch = useDispatch();
  const subsidiaryGroup = groupInformation?.subsidiaryGroup;
  const pricingList = groupInformation?.pricings;
  const defaultPricing =
    pricingList?.find(
      (x: any) =>
        x?.currency?.shortName == subsidiary?.currency &&
        x?.paymentOption?.code?.toLowerCase() == "c"
    ) ?? {};
  const limitList = groupInformation?.limits;
  const pricingGroup = subsidiaryGroup?.pricingGroup;
  const limitGroup = subsidiaryGroup?.limitGroup;
  const settlementGroup = subsidiaryGroup?.settlementGroup;
  const settlementPlans =
    groupInformation?.settlementPlans?.find(
      (x: any) =>
        x?.currency?.shortName == subsidiary?.currency && x.isActive == true
    ) ?? {};

  const getPricingGroups = useApi(reports.getPricingGroups);
  const getLimitGroups = useApi(reports.getLimitGroups);
  const getLimitItems = useApi(reports.getLimitItems);
  const getPaymentMethods = useApi(reports.getPaymentMethods);
  const getSubsidiaryGroups = useApi(reports.getSubsidiaryGroups);
  // configureSubsidiaryCustomizeConfig
  const [inputTypeText, setInputTypeText] = useState<any>({
    subsidiaryGroup: false,
    limitGroup: false,
    pricingGroup: false,
  });
  const classes = useModalStyles();
  const validate = Yup.object({
    // name: Yup.string().required('Required'),
  });
  // console.log({ groupInformation });
  const mapPricingGroups = () =>
    (getPricingGroups?.data?.data ?? []).map((x: any) => {
      return { name: x.name, val: x.id };
    });
  const pricingGroupOptions = mapPricingGroups();

  const mapLimitGroups = () =>
    (getLimitGroups?.data?.data ?? []).map((x: any) => {
      return { name: x.name, val: x.id };
    });
  const LimitGroupOptions = mapLimitGroups();

  const mapSubsidiaryGroups = () =>
    (getSubsidiaryGroups?.data?.data ?? []).map((x: any) => {
      return { name: x.name, val: x.id };
    });
  const subsidiaryGroupOptions = mapSubsidiaryGroups();

  const mapPaymentOptions = () =>
    (getPaymentMethods?.data?.data ?? []).map((x: any) => {
      return { name: x.name, val: x.code };
    });
  const paymethodOptions = mapPaymentOptions();

  const { currencies } = useSelector((state) => state.appSettingsReducer);
  const currencyOption = currencies.map((curr: any) => ({
    name: `${curr.name} (${curr.shortName})`,
    val: curr.id,
  }));

  useEffect(() => {
    getPricingGroups.request();
    getLimitGroups.request();
    getSubsidiaryGroups.request();
    getLimitItems.request();
    getPaymentMethods.request();
  }, []);

  const InputSwitchHandler = (inputType: boolean, field: string) => (
    <sup
      className={styles.inputSwitchHandler}
      onClick={() =>
        setInputTypeText({ ...inputTypeText, [field]: !inputTypeText[field] })
      }
    >
      {inputType ? "use existing" : "enter new"}
    </sup>
  );

  const submitHandler = (values: any) => {
    const x = window.confirm(
      `A new configuration will be applied for merchant "${subsidiary.name}"`
    );
    // console.log({ x });
    if (!x) return;
    // console.log(values);
    var payload = formatConfigPayload(values, inputTypeText);
    dispatch(openLoader());
    var _rr: any = reports;
    var req = subsidiaryApi.configureSubsidiaryCustomizeConfig(
      subsidiary.id,
      payload
    );
    req
      .then((res: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
        closeModal();
        cb();
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data || {};
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };
  return (
    <div className={styles.modalwrapper}>
      <div className={styles.modalhead}>
        <h3 className={styles.modalheadh3}>
          {subsidiary ? "Update Group" : "Add a new group"}
        </h3>
        <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
      </div>
      <Divider style={{ margin: 0, padding: 0 }} />
      <Formik
        initialValues={{
          id: subsidiary?.id ?? "",
          name: subsidiary?.name ?? "",
          description: subsidiary?.description ?? "",
          subsidiaryGroupName: "",
          subsidiaryGroupDescription: subsidiaryGroup?.description,
          subsidiaryGroupId: subsidiaryGroup?.id,
          pricingGroupName: "",
          pricingGroupDescription: pricingGroup?.description,
          pricingGroupId: pricingGroup?.id,
          limitGroupName: "",
          limitGroupDescription: limitGroup?.description,
          limitGroupId: limitGroup?.id,
          settlementGroupId: settlementGroup?.id,
          settlementGroupName: "",
          paymentOption: defaultPricing?.paymentOption?.code,
          currency: defaultPricing?.currency?.id,
          fee: (Number(defaultPricing?.fee ?? 0) * 100).toFixed(6),
          cap: defaultPricing?.cap,
          flatValue: defaultPricing?.flatValue,
          internationalFee: (
            Number(defaultPricing?.internationalFee) * 100
          ).toFixed(6),
          internationalCap: defaultPricing?.internationalCap,
          internationalFlatValue: defaultPricing?.internationalFlatValue,
          ...formatLimitValueToForm(limitList),
        }}
        validationSchema={validate}
        onSubmit={submitHandler}
      >
        {(props) => (
          <div
            style={{
              padding: "32px 24px",
            }}
          >
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>
                        Subsidiary Group Name{" "}
                        {InputSwitchHandler(
                          inputTypeText.subsidiaryGroup,
                          "subsidiaryGroup"
                        )}
                      </span>
                    </InputLabel>
                    <Field
                      as={
                        inputTypeText.subsidiaryGroup
                          ? TextField
                          : SelectWrapper
                      }
                      helperText={
                        <ErrorMessage
                          name={
                            inputTypeText.subsidiaryGroup
                              ? "subsidiaryGroupName"
                              : "subsidiaryGroupId"
                          }
                        >
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name={
                        inputTypeText.subsidiaryGroup
                          ? "subsidiaryGroupName"
                          : "subsidiaryGroupId"
                      }
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      fullWidth
                      options={subsidiaryGroupOptions}
                      className={classes.select}
                    />
                  </div>
                  {inputTypeText.subsidiaryGroup && (
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>Description</span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="subsidiaryGroupDescription">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="subsidiaryGroupDescription"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            size="small"
                            options={paymethodOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>
                        Pricing Group Name{" "}
                        {InputSwitchHandler(
                          inputTypeText.pricingGroup,
                          "pricingGroup"
                        )}
                      </span>
                    </InputLabel>
                    <Field
                      as={
                        inputTypeText.pricingGroup ? TextField : SelectWrapper
                      }
                      helperText={
                        <ErrorMessage
                          name={
                            inputTypeText.pricingGroup
                              ? "pricingGroupName"
                              : "pricingGroupId"
                          }
                        >
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name={
                        inputTypeText.pricingGroup
                          ? "pricingGroupName"
                          : "pricingGroupId"
                      }
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      options={pricingGroupOptions}
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                  {inputTypeText.pricingGroup && (
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>Payment Method</span>
                          </InputLabel>
                          <Field
                            as={SelectWrapper}
                            helperText={
                              <ErrorMessage name="PaymentOption">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="paymentOption"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            size="small"
                            options={paymethodOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>Currency</span>
                          </InputLabel>
                          <Field
                            as={SelectWrapper}
                            helperText={
                              <ErrorMessage name="currency">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="currency"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            size="small"
                            options={currencyOption}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>Fee</span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="fee">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="fee"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            size="small"
                            options={pricingGroupOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>Cap</span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="cap">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="cap"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            size="small"
                            options={pricingGroupOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>
                              International Fee
                            </span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="internationalFee">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="internationalFee"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            size="small"
                            options={pricingGroupOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>
                              International Cap
                            </span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="internationalCap">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="internationalCap"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            size="small"
                            options={pricingGroupOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}> Flat Fee</span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="flatValue">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="flatValue"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            size="small"
                            options={pricingGroupOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>Intl. Flat Fee</span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="internationalFlatValue">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="internationalFlatValue"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            size="small"
                            options={pricingGroupOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      {/* Minimum Fee */}
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>
                              Minimum Fee Value
                            </span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="minimumFeeValue">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="minimumFeeValue"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            size="small"
                            options={pricingGroupOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      {/* Minimum Intl. Fee */}
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>
                              Minimum Fee Intl.
                            </span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="minimumFeeInternationalValue">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="minimumFeeInternationalValue"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            size="small"
                            options={pricingGroupOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      {/* Chargeback Fee */}
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>Chargeback Fee</span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="chargebackFeeValue">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="chargebackFeeValue"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            size="small"
                            options={pricingGroupOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                      {/* Refund Fee Value */}
                      <Grid item xs={12} md={6}>
                        <div className={styles.customerInfoSingle}>
                          <InputLabel>
                            <span className={styles.label}>
                              Refund Fee Value
                            </span>
                          </InputLabel>
                          <Field
                            as={TextField}
                            helperText={
                              <ErrorMessage name="refundFeeValue">
                                {(msg) => (
                                  <span style={{ color: "red" }}>{msg}</span>
                                )}
                              </ErrorMessage>
                            }
                            name="refundFeeValue"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            size="small"
                            options={pricingGroupOptions}
                            fullWidth
                            className={classes.select}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={styles.customerInfoSingle}>
                    <InputLabel>
                      <span className={styles.label}>
                        Limit Group Name{" "}
                        {InputSwitchHandler(
                          inputTypeText.limitGroup,
                          "limitGroup"
                        )}
                      </span>
                    </InputLabel>
                    <Field
                      as={inputTypeText.limitGroup ? TextField : SelectWrapper}
                      helperText={
                        <ErrorMessage
                          name={
                            inputTypeText.limitGroup
                              ? "limitGroupName"
                              : "limitGroupId"
                          }
                        >
                          {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                        </ErrorMessage>
                      }
                      name={
                        inputTypeText.limitGroup
                          ? "limitGroupName"
                          : "limitGroupId"
                      }
                      variant="outlined"
                      margin="normal"
                      type="text"
                      size="small"
                      options={LimitGroupOptions}
                      fullWidth
                      className={classes.select}
                    />
                  </div>
                  {inputTypeText.limitGroup && (
                    <Grid container spacing={1}>
                      {(getLimitItems?.data?.data ?? []).map((item: any) => (
                        <>
                          <Grid item xs={12} lg={6}>
                            <div className={styles.customerInfoSingle}>
                              <InputLabel>
                                <span className={styles.labelLimit}>
                                  {item.description}
                                </span>
                              </InputLabel>
                              <Field
                                as={TextField}
                                helperText={
                                  <ErrorMessage
                                    name={`limitItem${item.name}_${item.id}`}
                                  >
                                    {(msg) => (
                                      <span style={{ color: "red" }}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                }
                                name={`limitItem${item.name}_${item.id}`}
                                variant="outlined"
                                margin="normal"
                                type={
                                  item.valueType == "decimal"
                                    ? "number"
                                    : "text"
                                }
                                size="small"
                                options={pricingGroupOptions}
                                fullWidth
                                className={classes.select}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <div className={styles.customerInfoSingle}>
                              <InputLabel>
                                <span className={styles.labelLimit}>
                                  Int. {item.description}
                                </span>
                              </InputLabel>
                              <Field
                                as={TextField}
                                helperText={
                                  <ErrorMessage
                                    name={`limitItemInternational${item.name}_${item.id}`}
                                  >
                                    {(msg) => (
                                      <span style={{ color: "red" }}>
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                }
                                name={`limitItemInternational${item.name}_${item.id}`}
                                variant="outlined"
                                margin="normal"
                                type={
                                  item.valueType == "decimal"
                                    ? "number"
                                    : "text"
                                }
                                size="small"
                                options={pricingGroupOptions}
                                fullWidth
                                className={classes.select}
                              />
                            </div>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  )}
                </Grid>
                <UpdateSettlementGroup
                  FormProps={props}
                  InputTypeText={inputTypeText}
                  InputSwitchHandler={InputSwitchHandler}
                  currencyOptions={currencyOption}
                />
                <Grid item xs={12}>
                  <button type="submit" className={styles.Downloadbutton}>
                    <span className={styles.Downloadbutton_span}>
                      <img src={Mark} alt="" />
                    </span>
                    Add Customize Configurations
                  </button>
                </Grid>{" "}
              </Grid>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default UpdateSubsidiaryConfigModal;
