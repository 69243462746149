import React, { Suspense } from "react";
import { PageLoader } from "../../components/page-loader/PageLoader";

const InternalWalletComponent = React.lazy(() => import("./InternalWallets"));

const InternalWalletPage = () => (
  <Suspense fallback={<PageLoader />}>
    <InternalWalletComponent />
  </Suspense>
);

export default InternalWalletPage;
