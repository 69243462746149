import client from "./client";
import moment from "moment";

const getTransactionsSummary = (currency = "NGN") =>
  client.get("metric/transaction/summary?currency=" + currency);

// const getDashboardTransactionsSummary = (currency = "NGN") =>
//   client.get("/metric/v2?currency=" + currency);

const getDashboardTransactionsSummary = (
  currency = "NGN",
  fromDate,
  toDate
) => {
  let url = `/metric/v2?currency=${currency}`;

  if (fromDate) url += `&StartDate=${moment(fromDate).format("YYYY-MM-DD")}`;
  if (toDate)
    url += `&EndDate
=${moment(toDate).format("YYYY-MM-DD")}`;

  return client.get(url);
};

export default { getTransactionsSummary, getDashboardTransactionsSummary };

