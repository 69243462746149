import { useEffect } from "react";
import styles from "./UpdateSubsidiaryConfigModal.module.scss";
import { ErrorMessage, Field } from "formik";
import { Grid } from "@material-ui/core";
import { InputLabel, TextField } from "@material-ui/core";
import SelectWrapper from "../../Select";
import useApi from "../../../hooks/useApi";
import reports from "../../../api/report";
import { useModalStyles } from "./styles";

const UpdateSettlementGroup = ({
  FormProps,
  InputTypeText,
  InputSwitchHandler,
  currencyOptions,
  subsidiary,
  groupInformation,
}: any) => {
  const classes = useModalStyles();

  const getSettlementGroups = useApi(reports.getSettlementGroups);
  const formValues = FormProps.values;
  // console.log({ formValues })
  const mapSettlementGroups = () =>
    (getSettlementGroups?.data?.data ?? []).map((x: any) => {
      return { name: x.name, val: x.id };
    });
  const settlementGroupOptions = mapSettlementGroups();
  const settlementDestination = [
    { name: "Merchant Bank Account", val: 1 },
    { name: "Merchant Available Balance", val: 2 },
  ];
  const settlementPercentage = [
    { name: "Full Settlement", val: "F" },
    { name: "Partial Settlement", val: "P" },
  ];
  useEffect(() => {
    getSettlementGroups.request();
  }, []);

  return (
    <Grid item xs={12} md={3}>
      <div className={styles.customerInfoSingle}>
        <InputLabel>
          <span className={styles.label}>
            Settlement Group Name{" "}
            {InputSwitchHandler(
              InputTypeText.settlementGroup,
              "settlementGroup"
            )}
          </span>
        </InputLabel>
        <Field
          as={InputTypeText.settlementGroup ? TextField : SelectWrapper}
          helperText={
            <ErrorMessage
              name={
                InputTypeText.settlementGroup
                  ? "settlementGroupName"
                  : "settlementGroupId"
              }
            >
              {(msg) => <span style={{ color: "red" }}>{msg}</span>}
            </ErrorMessage>
          }
          name={
            InputTypeText.settlementGroup
              ? "settlementGroupName"
              : "settlementGroupId"
          }
          variant="outlined"
          margin="normal"
          type="text"
          size="small"
          options={settlementGroupOptions}
          fullWidth
          className={classes.select}
        />
      </div>
      {InputTypeText.settlementGroup && (
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <div className={styles.customerInfoSingle}>
              <InputLabel>
                <span className={styles.label}>Currency</span>
              </InputLabel>
              <Field
                as={SelectWrapper}
                helperText={
                  <ErrorMessage name="settlementCurrency">
                    {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                  </ErrorMessage>
                }
                name="settlementCurrency"
                variant="outlined"
                margin="normal"
                type="text"
                size="small"
                options={currencyOptions}
                fullWidth
                className={classes.select}
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={styles.customerInfoSingle}>
              <InputLabel>
                <span className={styles.label}>Settlement Destination</span>
              </InputLabel>
              <Field
                as={SelectWrapper}
                helperText={
                  <ErrorMessage name="settlementDestinationId">
                    {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                  </ErrorMessage>
                }
                name="settlementDestinationId"
                variant="outlined"
                margin="normal"
                type="text"
                size="small"
                options={settlementDestination}
                fullWidth
                className={classes.select}
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={styles.customerInfoSingle}>
              <InputLabel>
                <span className={styles.label}>Settlement Type</span>
              </InputLabel>
              <Field
                as={SelectWrapper}
                helperText={
                  <ErrorMessage name="settlementType">
                    {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                  </ErrorMessage>
                }
                name="settlementType"
                variant="outlined"
                margin="normal"
                type="text"
                size="small"
                options={settlementPercentage}
                fullWidth
                className={classes.select}
              />
            </div>
          </Grid>
          {formValues.settlementType != "F" && (
            <Grid item xs={12} lg={6}>
              <div className={styles.customerInfoSingle}>
                <InputLabel>
                  <span className={styles.label}>Initial Settlement (%)</span>
                </InputLabel>
                <Field
                  as={TextField}
                  helperText={
                    <ErrorMessage name="initialSettlementPercentage">
                      {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                    </ErrorMessage>
                  }
                  name="initialSettlementPercentage"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  size="small"
                  options={currencyOptions}
                  fullWidth
                  className={classes.select}
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <div className={styles.customerInfoSingle}>
              <InputLabel>
                <span className={styles.label}>
                  {" "}
                  {formValues.settlementType == "F"
                    ? "Settlement Day"
                    : "First Settlement Day"}
                </span>
              </InputLabel>
              <Field
                as={TextField}
                helperText={
                  <ErrorMessage name="initialSettlementDay">
                    {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                  </ErrorMessage>
                }
                name="initialSettlementDay"
                variant="outlined"
                margin="normal"
                type="number"
                size="small"
                options={currencyOptions}
                fullWidth
                className={classes.select}
              />
            </div>
          </Grid>
          {formValues.settlementType != "F" && (
            <Grid item xs={12} lg={6}>
              <div className={styles.customerInfoSingle}>
                <InputLabel>
                  <span className={styles.label}>Final Settlement Day</span>
                </InputLabel>
                <Field
                  as={TextField}
                  helperText={
                    <ErrorMessage name="finalSettlementDay">
                      {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                    </ErrorMessage>
                  }
                  name="finalSettlementDay"
                  variant="outlined"
                  margin="normal"
                  type="number"
                  size="small"
                  options={currencyOptions}
                  fullWidth
                  className={classes.select}
                />
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default UpdateSettlementGroup;
