import React, { useState, Fragment } from "react";
import styles from "./Payoutmenu.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import RefundIcon from "../../../assets/images/Icons/refund-icon.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import moment from "moment";
import { numberWithCommas } from "../../../utils/formatNumber";
import StatusView from "../../StatusView/StatusView";
import PayoutVerificationModal from "../../ModalContent/PayoutVerificationModal/PayoutVerificationModal";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";

type MenuProps = {
  data: any;
  cb?: Function | null | undefined;
};
const PayoutMenu = ({ data, cb }: MenuProps) => {
  const dispatch = useDispatch();
  const [menuOption, setMenuOption] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [verifyPayoutModalIsOpen, setVerifyPayoutModalIsOpen] =
    React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function closeVerifyPayoutModalIsOpen() {
    setVerifyPayoutModalIsOpen(false);
    cb && cb();
  }

  // const resendVerifyPayoutOtp = async () => {
  //   dispatch(openLoader());
  //   try {
  //     const batchId = data?.batchId;
  //     const res: any = await client.get(
  //       `/subsidiary/payout/resend/otp/${batchId}`
  //     );
  //     const message = res?.data?.message;
  //     dispatch(
  //       openToastAndSetContent({
  //         toastStyles: ToastSuccessStyles,
  //         toastContent: message,
  //       })
  //     );
  //     setVerifyPayoutModalIsOpen(true);
  //     closeModal && closeModal();
  //   } catch (error: any) {
  //     const { message } = error?.response?.data;
  //     dispatch(
  //       openToastAndSetContent({
  //         toastStyles: ToastErrorStyles,
  //         toastContent: message,
  //       })
  //     );
  //   } finally {
  //     dispatch(closeLoader());
  //   }
  // };

  const resendVerifyPayoutOtp = async () => {
    dispatch(openLoader());
    try {
      if (data?.debitCurrency === "USD") {
        // If USD payout, call the new endpoint
        const res: any = await client.post("/multi-currency/approve", {
          id: data?.id,
          approve: true,
        });
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: res?.data?.message || "USD Payout Approved",
          })
        );
        res?.data?.status === true && setVerifyPayoutModalIsOpen(true); // Open OTP modal for regular payouts
      } else {
        // If regular payout, call the old OTP endpoint
        const res: { data: { message: string } } = await client.get(
          `/subsidiary/payout/resend/otp/${data?.batchId}`
        );
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: res?.data?.message || "OTP Sent",
          })
        );
        setVerifyPayoutModalIsOpen(true); // Open OTP modal for regular payouts
      }
      closeModal && closeModal();
    } catch (error: any) {
      const message = error?.response?.data || "Something went wrong";
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    } finally {
      dispatch(closeLoader());
    }
  };

  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={ViewIcon}
                optionName={"View details"}
                onclick={() => setIsOpen(true)}
              />
              <div className={styles.approveDeclineContainer}>
                <button
                  className={styles.approve}
                  onClick={resendVerifyPayoutOtp}
                >
                  {" "}
                  Mark As Completed{" "}
                </button>
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>Payout Details</div>
              <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
            </div>
          </div>
          {data && (
            <div className={styles.modalContent}>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Date Initiated</div>
                <div className={styles.content}>
                  {moment(data?.dateInitiated).format("MMMM Do YYYY, h:mm a")}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Status</div>
                <div className={styles.content}>
                  <StatusView
                    status={data?.status}
                    green={"Approved || Successful"}
                    red={"Rejected || Failed || Declined"}
                    orange={"Processing || Pending"}
                    blue={"Awaiting Verification"}
                    purple="Awaiting Approval"
                  />
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Bank Name</div>
                <div className={styles.content}>{data?.bankName}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Payout fee</div>
                <div className={styles.content}>
                  {`${data?.debitCurrency} ${numberWithCommas(data?.fee)}`}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Description</div>
                <div className={styles.content}>{data?.description}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Date Settled</div>
                <div className={styles.content}>
                  {moment(data?.dateSettled).format("MMMM Do YYYY, h:mm a")}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}> Amount</div>
                <div className={styles.content}>{`${
                  data?.debitCurrency
                } ${numberWithCommas(data?.amount)}`}</div>
              </div>
              {/* <div className={styles.modalDetails}>
                <div className={styles.link}>Type</div>
                <div className={styles.content}> {data?.type}</div>
              </div> */}
              {/* <div className={styles.modalDetails}>
                <div className={styles.link}>Initiator</div>
                <div className={styles.content}> {data?.initiator}</div>
              </div> */}
              <div className={styles.modalDetails}>
                <div className={styles.link}>Approver</div>
                <div className={styles.content}>
                  {" "}
                  {data?.approver || "N/A"}{" "}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Account Number</div>
                <div className={styles.content}> {data?.accountNumber}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Debit Currency</div>
                <div className={styles.content}> {data?.debitCurrency}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Recipient Name</div>
                <div className={styles.content}> {data?.recipientName}</div>
              </div>

              <div className={styles.modalDetails}>
                <div className={styles.link}>Batch Reference#</div>
                <div className={styles.content}>{data?.batchReference}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Payout Reference#</div>
                <div className={styles.content}>{data?.payoutReference}</div>
              </div>
              {data?.status?.toLowerCase() == "awaiting verification" && (
                <div className={styles.verifyPayoutBtn}>
                  <button onClick={resendVerifyPayoutOtp}>Verify Payout</button>
                </div>
              )}
              <button
                className={styles.approve}
                onClick={resendVerifyPayoutOtp}
              >
                {" "}
                Mark As Completed{" "}
              </button>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={verifyPayoutModalIsOpen}
        onRequestClose={closeVerifyPayoutModalIsOpen}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <PayoutVerificationModal
          data={data}
          closeModal={closeVerifyPayoutModalIsOpen}
        />
      </Modal>
    </Fragment>
  );
};

export default PayoutMenu;
